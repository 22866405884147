<template>
    <div class="limiter">

        <div class="container-login100">
            <div class="wrap-calculation100">
                <form class="login100-form validate-form">
                    <span class="calc100-form-title">
                        <b>运费查询</b>
                    </span>

                    <div class="row" style="color: #ABB4BB; margin: 20px 0">
                        <div class="col-md-6">
                            <label style="padding-bottom: 5px;">*请输入包裹毛重为公斤 (Kg)。</label>
                            <br />
                            <label>*请输入尺寸重为厘米 (cm)。</label>
                        </div>
                        <div class="col-md-6">
                            <label>*以下报价以最平的运输方法计算, 价格仅供参考。</label>
                        </div>
                    </div>

                    <div class="row" style="margin: 20px 0">
                        <div class="col-md-4">
                            <label>货物类型</label> <!--敏感/普通-->
                            <div class="wrap-calc-input100 validate-calc-input">
                                <select class="calc-input100" type="text" name="marks" value="">
                                    <option value="normal">普通货物</option>
                                    <option value="sensitive">敏感货物</option>
                                </select>
                                <span class="focus-calc-input100"></span>
                                <span class="symbol-calc-input100">
                                    <fa-fa icon="warehouse"></fa-fa>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label>包裹毛重</label>
                            <div class="wrap-calc-input100 validate-calc-input">
                                <input class="calc-input100" type="text" name="marks">
                                <span class="focus-calc-input100"></span>
                                <span class="symbol-calc-input100">
                                    <fa-fa icon="weight"></fa-fa>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label>收货方式</label> <!--拼团/配送-->
                            <div class="wrap-calc-input100 validate-calc-input">
                                <select class="calc-input100" type="text" value="">
                                    <option value="group">拼团</option>
                                    <option value="delivery">配送</option>
                                </select>
                                <span class="focus-calc-input100"></span>
                                <span class="symbol-calc-input100">
                                    <fa-fa icon="location"></fa-fa>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin: 20px 0">

                        <div class="col-md-8">
                            <div class="col-md-12">
                                <label>包裹尺寸</label>
                            </div>
                            <div class="col-md-4" style="display: inline-flex; align-items: baseline;">
                                <div class="wrap-calc-input100 validate-calc-input">
                                    <input class="calc-input100" type="number" name="length">
                                    <span class="focus-calc-input100"></span>
                                    <span class="symbol-calc-input100">
                                        <fa-fa icon="ruler"></fa-fa>
                                    </span>
                                </div>
                                <fa-fa icon="times" style="padding-right: 10px;"></fa-fa>
                            </div>

                            <div class="col-md-4" style="display: inline-flex; align-items: baseline">
                                <div class="wrap-calc-input100 validate-calc-input">
                                    <input class="calc-input100" type="number" name="width">
                                    <span class="focus-calc-input100"></span>
                                    <span class="symbol-calc-input100">
                                        <fa-fa icon="ruler"></fa-fa>
                                    </span>
                                </div>
                                <fa-fa icon="times" style="padding-right: 10px;"></fa-fa>
                            </div>
                            <div class="col-md-4" style="display: inline-flex; align-items: baseline">
                                <div class="wrap-calc-input100 validate-calc-input">
                                    <input class="calc-input100" type="number" name="height">
                                    <span class="focus-calc-input100"></span>
                                    <span class="symbol-calc-input100">
                                        <fa-fa icon="ruler"></fa-fa>
                                    </span>
                                </div>
                                <!--<fa-fa icon="times" style="padding-right: 10px;"></fa-fa>-->
                            </div>

                        </div>
                        <div class="col-md-4">
                            <div class="col-md-12">
                                <label>件数</label>
                            </div>
                            <div class="col-md-12" style="display: inline-flex; align-items: baseline">
                                <div class="wrap-calc-input100 validate-calc-input">
                                    <input class="calc-input100" type="number" name="height">
                                    <span class="focus-calc-input100"></span>
                                    <span class="symbol-calc-input100">
                                        <fa-fa icon="ruler"></fa-fa>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row" style="align-items: center; margin: 20px 0;">
                        <div class="col-md-3">
                            预估运费
                            <br />
                            配送费用
                            <br />
                            其他费用
                        </div>
                        <div class="col-md-3">
                            <label style="color: #FB7401; font-weight: bold">0.00</label> <b>(SGD)</b>
                            <br />
                            <label style="color: #FB7401; font-weight: bold">3.00</label> <b>(SGD)</b>
                            <br />
                            <label style="color: #FB7401; font-weight: bold">0.00</label> <b>(SGD)</b>
                        </div>
                        <div class="col-md-6">
                            <div class="container-calc100-form-btn">
                                <button type="submit" class="calc100-form-btn"><b>查询</b></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="wrap-login100">
                <div class="login100-pic js-tilt" data-tilt>
                    <img src="../../images/amcon_norm_logo.png" alt="IMG">
                </div>

                <form class="login100-form validate-form" @submit.prevent="login">
                    <span class="login100-form-title">
                        <b>会员登录</b>
                    </span>

                    <div class="wrap-input100 validate-input">
                        <input class="input100" type="text" name="marks" placeholder="唛头, Marks" v-model="marks">
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
                            <fa-fa icon="box"></fa-fa>
                        </span>
                    </div>

                    <div class="wrap-input100 validate-input">
                        <input class="input100" type="text" name="contact" placeholder="联络号码, Contact No" v-model="contact">
                        <span class="focus-input100"></span>
                        <span class="symbol-input100">
                            <fa-fa icon="phone"></fa-fa>
                        </span>
                    </div>

                    <div class="container-login100-form-btn">
                        <button type="submit" class="login100-form-btn"><b>登录</b></button>
                    </div>
                    <a>注册会员</a>

                    <div class="text-center p-t-12">
                    </div>

                    <!--<div class="text-center p-t-136">
            </div>-->
                </form>
            </div>


        </div>
    </div>
</template>

<style scoped>
    @font-face {
        font-family: Poppins-Regular;
        src: url('../../fonts/poppins/Poppins-Regular.ttf');
    }

    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    body, html {
        height: 100%;
        font-family: Poppins-Regular, sans-serif;
    }

    .limiter {
        width: 100%;
        margin: 0 auto;
    }

    .container-login100 {
        width: 100%;
        min-height: 100vh;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: right;
        align-items: center;
        padding: 15px;
        background: -webkit-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: -o-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: -moz-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
    }

    .wrap-login100 {
        width: 600px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 150px 100px 150px 100px;
        /*padding: 40px 80px 40px 80px;*/
        margin-left: 70px;
    }

    .wrap-calculation100 {
        width: 850px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /*padding: 177px 130px 33px 95px;*/
        /*padding: 145px 80px 145px 80px;*/
        padding: 25px 80px 25px 80px;
    }

    @media (max-width: 992px) {
        .wrap-login100 {
            padding: 177px 90px 33px 85px;
        }
    }

    @media (max-width: 768px) {
        .wrap-login100 {
            padding: 100px 80px 33px 80px;
        }
    }

    @media (max-width: 576px) {
        .wrap-login100 {
            padding: 100px 15px 33px 15px;
        }
    }

    .text-center {
        text-align: center;
    }

    .p-t-12 {
        padding-top: 12px;
    }

    .p-t-136 {
        padding-top: 136px;
    }

    /* Image */
    .login100-pic {
        width: 316px;
    }

        .login100-pic img {
            max-width: 100%;
        }

    /* Input */
    input {
        outline: none;
        border: none;
    }

        input::-webkit-input-placeholder {
            color: #999999;
        }

        input:-moz-placeholder {
            color: #999999;
        }

        input::-moz-placeholder {
            color: #999999;
        }

        input:-ms-input-placeholder {
            color: #999999;
        }

    .validate-input {
        position: relative;
    }

    .wrap-input100 {
        position: relative;
        width: 100%;
        z-index: 1;
        margin-bottom: 10px;
    }

    .input100 {
        font-family: Poppins-Medium;
        font-size: 15px;
        line-height: 1.5;
        color: #666666;
        display: block;
        width: 100%;
        background: #e6e6e6;
        height: 50px;
        border-radius: 25px;
        padding: 0 30px 0 68px;
    }

    .symbol-input100 {
        font-size: 15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        position: absolute;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-left: 35px;
        pointer-events: none;
        color: #666666;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
    }

    .input100:focus + .focus-input100 {
        -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
        animation: anim-shadow 0.5s ease-in-out forwards;
    }

        .input100:focus + .focus-input100 + .symbol-input100 {
            color: #AC3121; /* #57b846 */
            padding-left: 28px;
        }

    /* Calculation Input */
    .focus-calc-input100 {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 0px 0px;
        color: #AC3121;
    }

    .validate-calc-input {
        position: relative;
    }

    .wrap-calc-input100 {
        position: relative;
        width: 100%;
        z-index: 1;
        margin-bottom: 10px;
        padding-right: 10px;
    }

    .calc-input100 {
        font-family: Poppins-Medium;
        font-size: 15px;
        line-height: 1.5;
        color: #666666;
        display: block;
        width: 100%;
        background: white;
        height: 50px;
        padding: 0 20px 0 40px;
        border-radius: 5px;
        border: #e6e6e6 1px solid;
    }

    .symbol-calc-input100 {
        font-size: 15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        position: absolute;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-left: 10px;
        pointer-events: none;
        color: #666666;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
    }

    .calc-input100:focus + .focus-calc-input100 + .symbol-calc-input100 {
        color: #AC3121; /* #57b846 */
    }

    .focus-calc-input100 {
        outline: #AC3121;
        display: block;
        position: absolute;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 0px 0px;
        color: #AC3121;
    }

    /* Login Container */
    .container-login100-form-btn {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 20px;
    }

    .container-calc100-form-btn {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
    }

    /* Login Button */
    button {
        outline: none !important;
        border: none;
        background: transparent;
    }

        button:hover {
            cursor: pointer;
        }

    .login100-form-btn {
        font-family: Montserrat-Bold;
        font-size: 15px;
        line-height: 1.5;
        color: #fff;
        text-transform: uppercase;
        width: 100%;
        height: 50px;
        border-radius: 25px;
        background: -webkit-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: -o-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: -moz-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 25px;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
        text-decoration: none;
    }

        .login100-form-btn:hover {
            background: -webkit-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
            background: #333333;
        }

    .calc100-form-btn {
        font-family: Montserrat-Bold;
        font-size: 15px;
        line-height: 1.5;
        color: #fff;
        text-transform: uppercase;
        width: 31%;
        height: 45px;
        border-radius: 5px;
        background: #015697;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 25px;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
        text-decoration: none;
    }

        .calc100-form-btn:hover {
            background: -webkit-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
            background: #333333;
        }

    .login100-form-title {
        font-family: Poppins-Bold;
        font-size: 24px;
        color: #333333;
        line-height: 1.2;
        text-align: center;
        width: 100%;
        display: block;
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .calc100-form-title {
        font-family: Poppins-Bold;
        font-size: 20px;
        color: #054C08;
        line-height: 1.2;
        text-align: left;
        width: 100%;
        display: block;
        padding-top: 15px;
    }
</style>