<template>
    <div @click="$emit('close')"></div>
    <dialog open>
        <header>
            <slot name="header"></slot>
        </header>
        <section class="content-container">
            <slot name="content"></slot>
        </section>
    </dialog>
</template>

<style scoped>
    div {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 10;
    }

    dialog {
        position: fixed;
        z-index: 100;
        border: none;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
        padding: 0;
        margin: 0;
        overflow: auto;
        left: calc(50%);
        top: calc(50%);
        width: 80%;
        transform: translate(-50%, -50%);
        font-weight: bold;
    }

    .content-container {
        background-color: white;
        font-size: 11px;
    }

    header {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 5px;
        background: -webkit-linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        background: -o-linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        background: -moz-linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        background: linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        color: white;
        text-align: center;
    }
</style>