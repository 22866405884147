<template>
    <the-header title="用户资料 (User Profile)"></the-header>

    <div class="main-page">
        <div class="main-container">
            <div class="row" style="margin: 1% 0%; font-weight: bold; align-items: center;">
                <div class="col-lg-1 col-sm-2">
                    <label>唛头<br />Marks</label>
                </div>
                <div class="col-lg-2 col-sm-4">
                    <input class="input-container" v-model="enteredMarks" disabled="disabled" />
                </div>
            </div>
            <div class="second-form">
                <div class="row" style="margin: 1% 0%; align-items: center">
                    <div class="col-lg-1 col-sm-2">
                        <label>姓名<br />Name</label>
                    </div>
                    <div class="col-lg-2 col-sm-4">
                        <input class="input-container" v-model="enteredCustName" />
                    </div>
                    <div class="col-lg-1 col-sm-2">
                        <label>备注<br />Remark</label>
                    </div>
                    <div class="col-lg-2 col-sm-4">
                        <input class="input-container" v-model="enteredCustRemark" />
                    </div>
                </div>
                <div class="row" style="margin: 15px 0px; align-items: center">
                    <div class="col-lg-1 col-sm-2">
                        <label>联络号码<br />Contact No</label>
                    </div>
                    <div class="col-lg-2 col-sm-4">
                        <input class="input-container" v-model="enteredCustContactNo" /> <br />
                        <label style="color: red" v-if="contactMsg != ''">*{{ contactMsg }}</label>
                    </div>
                    <div class="col-lg-1 col-sm-2">
                        <label>微信<br />WEIXIN</label>
                    </div>
                    <div class="col-lg-2 col-sm-4">
                        <input class="input-container" v-model="enteredCustWeiXin" />
                    </div>
                    <div class="col-lg-1 col-sm-2">
                        <label>QQ</label>
                    </div>
                    <div class="col-lg-2 col-sm-4">
                        <input class="input-container" v-model="enteredCustQq" />
                    </div>
                    <div class="col-lg-1 col-sm-2">
                        <label>电邮<br />Email</label>
                    </div>
                    <div class="col-lg-2 col-sm-4">
                        <input class="input-container" v-model="enteredCustEmail" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" style="height: 100%">
                    <DxDataGrid id="gridContainer"
                                :ref="dataGridRefName"
                                :data-source="addressList"
                                :word-wrap-enabled="false"
                                :column-auto-width="true"
                                :show-borders="true"
                                :show-row-lines="true"
                                :on-focused-cell-changing="onFocusedCellChanging">

                        <DxSorting mode="none" />

                        <DxKeyboardNavigation :edit-on-key-press="editOnKeyPress"
                                              :enter-key-action="enterKeyAction"
                                              :enter-key-direction="enterKeyDirection" />

                        <DxEditing :allow-updating="true"
                                   :refresh-mode="reshape"
                                   mode="cell"
                                   start-edit-action="click"
                                   :select-text-on-edit-start="true" />

                        <DxColumn data-field="isDefault"
                                  data-type="boolean"
                                  width="100"
                                  caption="默认 (Default)" />

                        <DxColumn data-field="address"
                                  min-width="250"
                                  caption="地址 (Address)" />

                        <DxColumn data-field="postalCode"
                                  width="150"
                                  caption="邮编 (Postal Code)" />

                        <DxColumn data-field="type"
                                  width="150"
                                  caption="类型 (Address Type)">
                            <DxLookup :data-source="type"
                                      value-expr="id"
                                      display-expr="text" />
                        </DxColumn>

                        <DxColumn data-field="contactPerson"
                                  width="170"
                                  caption="联系人 (Contact Person)" />

                        <DxColumn data-field="contactNumber"
                                  width="170"
                                  caption="联系号码 (Contact No)" />

                        <DxColumn data-field="email"
                                  caption="电邮 (Email)" />

                        <DxColumn data-field="building"
                                  caption="大厦 (Building)" />

                    </DxDataGrid>
                    <div style="text-align: right; padding: 15px 0px;" v-if="updatedDateTime != null">
                        <span>Profile last update on <span style="color: rgb(128, 128, 128, 0.8) ">{{ formattedDate }}</span></span>
                    </div>
                </div>

            </div>

            <div class="col-12">
                <div style="text-align: center; width: 100%; margin-top: 10px; margin-bottom: 10px;">
                    <DxButton text="保存 (Save)"
                              type="success"
                              @click="editCustomer" />

                    <DxButton text="取消 (Cancel)"
                              style="margin-left: 5px"
                              type="danger"
                              @click="selfsvcMenu" />
                </div>
            </div>

        </div>


    </div>
    <!--<the-footer></the-footer>-->
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxLookup,
        DxKeyboardNavigation,
        DxSorting
        //DxSelection
    } from 'devextreme-vue/data-grid';
    import { DxButton } from 'devextreme-vue/button';

    const type = [{
        id: 'Office', text: '公司 (Office)',
    }, {
        id: 'Home', text: '住家 (Home)',
    }];

    export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing,
            DxLookup,
            DxButton,
            DxKeyboardNavigation,
            DxSorting
            //DxSelection
        },
        async mounted() {
            const params = new URLSearchParams();
            params.append('CustId', this.$store.state.custId);

            await axios.post('https://amcon.express/booxapi/api/v2/selfService/getCustomerByCustId?' + params.toString())
                .then(response => {
                    console.log(response.data.result)
                    this.selectedCust = response.data.result;

                    this.updatedDateTime = this.selectedCust.updatedDateTime
                    this.enteredCustRemark = this.selectedCust.custRemark
                    this.enteredMarks = this.selectedCust.marks
                    this.enteredCustName = this.selectedCust.custName
                    this.enteredCustContactNo = this.selectedCust.custContactNo
                    this.enteredCustWeiXin = this.selectedCust.custWeiXin
                    this.enteredCustQq = this.selectedCust.custQq
                    this.enteredCustEmail = this.selectedCust.custEmail
                    this.addressList = this.selectedCust.addressBook

                    while (this.selectedCust.addressBook.length < 4) {
                        this.selectedCust.addressBook.push({ isDefault: false, address: '', postalCode: '', type: '', contactPerson: '', contactNumber: '', email: '' });
                    }
                });

            document.title = "用户资料";
            console.log(this.updatedDateTime)
        },
        data() {
            return {
                //customer details
                updatedDateTime: null,
                enteredCustName: '',
                enteredCustRemark: '',
                enteredCustContactNo: '',
                enteredCustWeiXin: '',
                enteredCustQq: '',
                enteredCustEmail: '',
                enteredMarks: '',

                //address
                type,

                // keyboard navi
                dataGridRefName: 'dataGrid',
                editOnKeyPress: true,
                enterKeyDirection: 'row',
                enterKeyAction: 'moveFocus',

                contactMsg: '',
            };
        },
        computed: {
            formattedDate() {
                return moment(this.updatedDateTime).format('YYYY-MM-DD, HH:mm:ss A');
                // Replace 'YYYY-MM-DD HH:mm:ss' with the desired format
            },
        },
        methods: {
            onFocusedCellChanging(e) {
                e.isHighlighted = true;
            },
            editCustomer() {
                //if (this.enteredCustName === '') {
                //    alert('请输入客户名 \nPlease enter Customer Name')
                //}
                //else if (this.selectedMainGroup === null) {
                //    alert('请输入团号 \nPlease select Main Group')
                //}
                if (this.enteredCustContactNo === '') {
                    this.contactMsg = "联络号码不能为空 (Contact No cannot be empty)"
                }
                //else if (this.enteredCustEmail === '') {
                //    alert('请输入电邮 \nPlease enter Email')
                //}
                else if (this.selectedCust.addressBook.length <= 0) {
                    alert('地址簿不能为空 \nAddress book must not be empty')
                }

                else {
                    this.isLoading = true;
                    axios.post('https://amcon.express/booxapi/api/v2/selfService/editCustomer', {
                        custId: this.$store.state.custId,
                        custName: this.enteredCustName,
                        marks: this.enteredMarks,
                        custContactNo: this.enteredCustContactNo,
                        custWeiXin: this.enteredCustWeiXin,
                        custQq: this.enteredCustQq,
                        custEmail: this.enteredCustEmail,
                        custRemark: this.enteredCustRemark,
                        updateBy: this.enteredMarks,
                        addressBook: this.addressList
                    })
                        .then(response => {
                            const success = response.data["success"]
                            if (success === true) {
                                this.contactMsg = ""
                                this.isLoading = false;
                                this.$swal.fire({
                                    title: "保存成功",
                                    text: "Profile Saved Successfully",
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                                this.$router.push('/selfsvcMenu')
                            }
                            else {
                                this.$swal.fire({
                                    title: "保存失败",
                                    text: "Profile Saved Unsuccessfully",
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                            }

                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            },
            selfsvcMenu() {
                this.$router.push('/selfsvcMenu')
            }
        }
    };
</script>

<style scoped>
    /* main */
    .first-form {
        border-top: solid 1px #D8D7D7;
        font-weight: bold;
    }

    .second-form {
        border-top: solid 1px #D8D7D7;
        font-weight: bold;
    }

    /* footer */
    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 65px;
        background-color: white;
    }

    /* bootstrap */
    .row {
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 768px) {
        .row {
            align-items: center;
        }

        .md-btn {
            writing-mode: vertical-lr;
            display: inline-grid;
            align-items: center;
        }

        button {
            margin-top: 20px;
            margin: 15px 0px 0px 5px;
        }

        img {
            margin: 20px 0px;
        }

        .input-container, .select-container {
            margin-bottom: 20px;
            width: 100%;
            align-self: center;
            height: 30px;
        }
        label {
            margin-bottom: 10px;
        }
    }
</style>