import { createApp } from 'vue';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { createRouter, createWebHistory } from 'vue-router';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App.vue';
import 'devextreme/dist/css/dx.light.css';
import VueToastification from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import LoginPage from './pages/LoginPage.vue';
import LoginPageV2 from './pages/LoginPageV2.vue'
import LoginPageV3 from './pages/LoginPageV3.vue'
import TheHeader from './components/TheHeader.vue'
import SelfsvcMenu from './pages/SelfsvcMenu.vue'
import CreateOrder from './pages/CreateOrder.vue'
import ParcelTracking from './pages/ParcelTracking.vue'
import ParcelDetail from './detailDialog/ParcelDetail.vue'
import ViewOrder from './order/ViewOrder.vue'
import OrderHisoty from './pages/OrderHistory.vue'
import HistoryDetail from './detailDialog/HistoryDetail.vue'
import BcProfile from './pages/BcProfile.vue'
import GroupProfile from './pages/GroupProfile.vue'
import RegisterCustomer from './pages/RegisterCustomer.vue'
import AddAddress from './dialog/AddAddress.vue'
import TheFooter from './components/TheFooter.vue'
import TheLoader from './components/TheLoader.vue'
import DropDown from './order/DropDown.vue'
import ViewBooking from './detailDialog/ViewBooking.vue'
import EditBooking from './editDialog/EditBooking.vue'
import ParcelLookup from './pages/ParcelLookup.vue'


const store = createStore({
    state() {
        return {
            courierList: [],
            parcelList: [],
            custList: [],
            marksList: [],
            selectedCust: null,
            selectedCustOrder: null,
            custId: 0,
            marks: "",
            historyList: [],
            selectedBooking: 0,
        }
    },
    mutations: {
        setCustId(state, custId) {
            state.custId = custId
        },
        setMarks(state, marks) {
            state.marks = marks
        },
        setSelectedParcel(state, id) {
            state.selectedParcel = state.parcelList.find(parcel => parcel.id === id);
        },
        setSelectedHistory(state, id) {
            state.selectedHistory = state.historyList.find(history => history.customerOrderId === id);
        },
        setSelectedBooking(state, id) {
            state.selectedBooking = id;
        },
    },
    plugins: [createPersistedState()]
})

const app = createApp(App);

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/loginPage', component: LoginPage },
        { path: '/', component: LoginPageV2 },
        { path: '/loginPageV3', component: LoginPageV3 },
        { path: '/selfsvcMenu', component: SelfsvcMenu },
        { path: '/createOrder', component: CreateOrder },
        { path: '/parcelTracking', component: ParcelTracking },
        { path: '/viewOrder', component: ViewOrder },
        { path: '/orderHistory', component: OrderHisoty },
        { path: '/bcProfile', component: BcProfile },
        { path: '/groupProfile', component: GroupProfile },
        { path: '/registerCustomer', component: RegisterCustomer },
        { path: '/parcelLookup', component: ParcelLookup },
    ]
});

app.component('login-page', LoginPage);
app.component('the-header', TheHeader);
app.component('selfsvc-menu', SelfsvcMenu);
app.component('parcel-detail', ParcelDetail);
app.component('history-detail', HistoryDetail);
app.component('add-address', AddAddress);
app.component('the-footer', TheFooter);
app.component('the-loader', TheLoader);
app.component('drop-down', DropDown);
app.component('view-booking', ViewBooking);
app.component('edit-booking', EditBooking);

//library.add(fas);
library.add(fas, fab);

app.use(router);
app.use(store);
app.use(VueToastification);
app.use(VueSweetalert2)
app.use(Vue3Toastify)

app.component('fa-fa', FontAwesomeIcon).mount('#app');

