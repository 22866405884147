<template>
    <div v-if="isLoading">
        <div class="layer"></div>
        <div class="loader-container" v-if="isLoading">
            <div class="spinner"></div>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            isLoading: {
                type: Boolean,
                required: true,
            },
        },
    };
</script>

<style>
    .layer {
        position: fixed;
        top: 6vh;
        left: 0;
        height: 100vh;
        width: 100%;
        z-index: 9999;
    }

    .loader-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        background: rgba(0,0,0,0.2);
    }

    .spinner {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        background: conic-gradient(#0000 10%,#ac3121);
        -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 11.5px),#000 0);
        animation: spinner-zp9dbg 1s infinite linear;
    }

    @keyframes spinner-zp9dbg {
        to {
            transform: rotate(1turn);
        }
    }
</style>