<template>
    <the-header title="用户资料 (User Profile)"></the-header>

    <div class="main-page">
        <div class="main-container">
            <div class="row" style="margin-top: 10px; margin-bottom: 10px; font-weight: bold; align-items: center;">
                <div class="col-md-1">
                    <label>姓名<br />Name</label>
                </div>
                <div class="col-md-2">
                    <input class="input-container" v-model="enteredCustName" />
                </div>
            </div>
            <div class="first-form">
                <div class="row" style="margin-top: 15px; align-items: center;">

                    <div class="col-md-1">
                        <label>公司<br />Company</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" v-model="enteredCompany" />
                    </div>
                    <div class="col-md-1">
                        <label>公司地址<br />Company Addr</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" v-model="enteredCompanyAddr" />
                    </div>
                    <div class="col-md-1">
                        <label>邮政码<br />Postal Code</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" v-model="enteredPostalCode" />
                    </div>
                </div>
                <div class="row" style="margin-top: 15px; margin-bottom: 15px; align-items: center;">
                    <div class="col-md-1">
                        <label>客户备注<br />Cust Remark</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" v-model="enteredCustRemark" />
                    </div>
                    <div class="col-md-1">
                        <label>电邮<br />Email</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" v-model="enteredCustEmail" />
                    </div>
                    <div class="col-md-1">
                        <label>联系人<br />Ctc Person</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" v-model="enteredCtcPerson" />
                    </div>
                    <div class="col-md-1">
                        <label>联系号码<br />Ctc No</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" v-model="enteredCtcNo" />
                    </div>
                </div>
            </div>

            <div class="row" style="height: 50%;">
                <div class="col-md-3" style="height: 100%">
                    <DxDataGrid id="gridContainer"
                                :ref="dataGridRefName"
                                :data-source="marksList"
                                :word-wrap-enabled="true"
                                :show-borders="true"
                                :show-row-lines="true"
                                style="height: 100%; margin-top: 10px">

                        <DxSorting mode="none" />

                        <DxColumn data-field="marks"
                                  caption="唛头 (Marks)"
                                  alignment="left" />

                    </DxDataGrid>
                </div>
                
                <div class="col-md-9" style="height: 100%">
                    <DxDataGrid id="gridContainer"
                                :ref="dataGridRefName"
                                :data-source="addressList"
                                :word-wrap-enabled="true"
                                :column-auto-width="true"
                                :show-borders="true"
                                :show-row-lines="true"
                                :repaint-changes-only="true"
                                :remote-operations="true"
                                style="height: 100%; margin-top: 10px">

                        <DxKeyboardNavigation :edit-on-key-press="editOnKeyPress"
                                              :enter-key-action="enterKeyAction"
                                              :enter-key-direction="enterKeyDirection" />

                        <DxSorting mode="none" />

                        <DxEditing :allow-adding="false"
                                   :allow-deleting="false"
                                   :allow-updating="true"
                                   :refresh-mode="reshape"
                                   mode="cell"
                                   start-edit-action="click"
                                   :select-text-on-edit-start="true" />

                        <DxColumn data-field="isDefault"
                                  data-type="boolean"
                                  width="100"
                                  caption="默认 (Default)" />

                        <DxColumn data-field="custId"
                                  data-type="number"
                                  caption=""
                                  width="0"
                                  :allow-editing="false"
                                  :calculate-display-value="setValue" />

                        <DxColumn data-field="address"
                                  caption="地址 (Address)" />

                        <DxColumn data-field="postalCode"
                                  caption="邮编 (Postal Code)" />

                        <DxColumn data-field="type"
                                  caption="类型 (Address Type)">
                            <DxLookup :data-source="type"
                                      value-expr="id"
                                      display-expr="text" />
                        </DxColumn>

                        <DxColumn data-field="contactPerson"
                                  caption="联系人 (Contact Person)" />

                        <DxColumn data-field="contactNumber"
                                  caption="联系号码 (Contact No)" />

                        <DxColumn data-field="email"
                                  caption="电邮 (Email)" />

                    </DxDataGrid>
                    <div style="text-align: right; padding: 15px 0px;" v-if="updatedDateTime != null">
                        <label>Profile last update on <span style="color: rgb(128, 128, 128, 0.8) ">{{ formattedDate }}</span></label>
                    </div>

                </div>
            </div>

            <div class="col-12">
                <div style="text-align: center; width: 100%; margin-top: 10px; margin-bottom: 10px;">
                    <DxButton text="保存 (Save)"
                              type="success"
                              @click="editB2bCust" />

                    <DxButton text="取消 (Cancel)"
                              style="margin-left: 5px"
                              type="danger"
                              @click="selfsvcMenu" />
                </div>
            </div>
        </div>
    </div>
    <!--<the-footer></the-footer>-->
</template>

<script>
    import axios from 'axios'
    import moment from 'moment';
    import {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxLookup,
        DxKeyboardNavigation,
        DxSorting
    } from 'devextreme-vue/data-grid';
    import { DxButton } from 'devextreme-vue/button';

    const type = [{
        id: 'Office', text: '公司 (Office)',
    }, {
        id: 'Home', text: '住家 (Home)',
        }, {
            id: 'Warehouse', text: '货仓 (Warehouse)',
        }];

    const URL = 'https://amcon.express/booxapi/api/v2/selfService';

    async function sendBatchRequest(url, changes) {
        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(changes),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
            credentials: 'include',
        });

        if (!result.ok) {
            const json = await result.json();

            throw json.Message;
        }
    }

    async function processBatchRequest(url, changes, component) {
        await sendBatchRequest(url, changes);
        await component.refresh(true);
        component.cancelEditData();
    }
    export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing,
            DxLookup,
            DxButton,
            DxKeyboardNavigation,
            DxSorting
        },
        mounted() {
            const params = new URLSearchParams();
            params.append('CustId', this.$store.state.custId);

            axios.post('https://amcon.express/booxapi/api/v2/selfService/getCustomerByCustId?' + params.toString())
                .then(response => {
                    this.isLoading = false;
                    this.selectedB2bCustList = response.data.result; console.log(response.data.result);

                    this.updatedDateTime = this.selectedB2bCustList.updatedDateTime
                    this.enteredCustName = this.selectedB2bCustList.custName
                    this.enteredCompany = this.selectedB2bCustList.custCompany
                    this.enteredCompanyAddr = this.selectedB2bCustList.custCompanyAddress
                    this.enteredPostalCode = this.selectedB2bCustList.custPostal
                    this.enteredCustRemark = this.selectedB2bCustList.custRemark
                    this.enteredCustEmail = this.selectedB2bCustList.custEmail
                    this.enteredCtcPerson = this.selectedB2bCustList.contactPerson
                    this.enteredCtcNo = this.selectedB2bCustList.custContactNo
                    this.enteredCertNo = this.selectedB2bCustList.certificateNo
                    this.enteredCharges = this.selectedB2bCustList.charges
                    this.addressList = this.selectedB2bCustList.addressBook
                    this.marksList = this.selectedB2bCustList.marksList

                    while (this.selectedB2bCustList.addressBook.length < 4) {
                        this.selectedB2bCustList.addressBook.push({ isDefault: false, address: '', postalCode: '', type: '', contactPerson: '', contactNumber: '', email: '' });
                    }

                });

            document.title = "用户资料"
        },
        data() {
            return {
                //customer details
                updatedDateTime: null,
                enteredCustName: '',
                enteredCompany: '',
                enteredCompanyAddr: '',
                enteredPostalCode: '',
                enteredCustRemark: '',
                enteredCustContactNo: '',
                enteredCustEmail: '',
                enteredCtcPerson: '',
                enteredCtcNo: '',
                enteredCertNo: '',
                enteredCharges: '',

                //address book
                type,
                addressList: [],
                marksList: [],

                editOnKeyPress: true,
                enterKeyDirection: 'row',
                enterKeyAction: 'moveFocus',

            };
        },
        computed: {
            formattedDate() {
                return moment(this.updatedDateTime).format('YYYY-MM-DD, HH:mm:ss A');
                // Replace 'YYYY-MM-DD HH:mm:ss' with the desired format
            },
        },
        methods: {
            setValue(rowData) {
                rowData.custId = this.$store.state.custId
                return rowData.custId;
            },
            saveAddress(e) {
                if (e.changes.length) {
                    e.promise = processBatchRequest(`${URL}/batchAddress`, e.changes, e.component);
                }
            },
            editB2bCust() {
                axios.post('https://amcon.express/booxapi/api/v2/selfService/editB2bCustomer', {
                    custId: this.$store.state.custId,
                    custName: this.enteredCustName,
                    custCompany: this.enteredCompany,
                    custCompanyAddress: this.enteredCompanyAddr,
                    custPostal: this.enteredPostalCode,
                    custRemark: this.enteredCustRemark,
                    custEmail: this.enteredCustEmail,
                    contactPerson: this.enteredCtcPerson,
                    custContactNo: this.enteredCtcNo,
                    updatedBy: this.$store.state.marks,
                    addressBook: this.addressList,
                })
                    .then(response => {
                        const success = response.data["success"]
                        if (success === true) {
                            this.$swal.fire({
                                title: "保存成功",
                                text: "Profile Saved Successfully",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1500
                            });
                            this.$router.push('/selfsvcMenu')
                        }
                        else {
                            this.$swal.fire({
                                title: "保存失败",
                                text: "Profile Saved Unsuccessfully",
                                icon: "error",
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })

            },
            selfsvcMenu() {
                this.$router.push('/selfsvcMenu')
            }
        }
    };
</script>

<style scoped>
    /* main */
    .first-form {
        border-top: solid 1px #D8D7D7;
        border-bottom: solid 1px #D8D7D7;
        font-weight: bold;
    }

    .third-form {
        font-weight: bold;
    }

    .table-scroll {
        height: 58vh;
        overflow: auto;
    }

    /* table */
    .address-table {
        margin-top: 5px;
        width: 100%;
        border: 1px solid #D8D7D7;
        font-weight: bold;
    }

        .address-table tr th {
            padding: 10px 5px;
            background-color: rgb(254, 244, 112, 0.2);
            border: 1px solid #D8D7D7;
        }

        .address-table tr td {
            padding: 10px 5px;
            border: 1px solid #D8D7D7;
        }


    /* icon */
    .magnifying-glass {
        font-size: 15px;
    }

    /* footer */
    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 65px;
        background-color: white;
    }

    /* bootstrap */
    .row {
        margin-left: auto;
        margin-right: auto;
    }

    /* set class style */
    .red {
        color: #FF0000;
        font-weight: bold;
    }

    .green {
        color: #B6FF87;
        font-weight: bold;
    }

    .red-bg {
        background: #FF7779;
        font-weight: bold;
    }

    @media screen and (max-width: 768px) {
        .md-btn {
            writing-mode: vertical-lr;
            display: inline-grid;
            align-items: center;
        }

        button {
            margin-top: 20px;
            margin: 15px 0px 0px 5px;
        }

        img {
            margin: 20px 0px;
        }
    }
</style>