<template>
    <the-header title="包裹追踪 (Parcel Tracking)"></the-header>

    <the-loader :isLoading="isLoading"></the-loader>

    <view-booking v-if="openView">
        <template #header>
            <div class="row">
                <div class="col-md-11">
                    <h4 style="font-weight: bold; padding-left: 80px; font-weight: bold;">
                        编辑 {{ enteredInternalRef }} 预约
                    </h4>
                </div>
                <div class="col-md-1">
                    <fa-fa icon="times" class="delete-icon" style="float: right; margin-right: 20px; margin-top: 3px;" @click="closeView()"></fa-fa>
                </div>
            </div>
        </template>
        <template #content>
            <div class="row">
                <div class="col-12" style="margin-top: 50px; margin-bottom: 80px;">
                    <div class="row" style="align-items: center;">
                        <div class="col-2">
                            <label class="label-container">预约日期 <br /> (Booking Date)</label>
                        </div>
                        <div class="col-4">
                            <input type="date" v-model="enteredReceivingDate" disabled>
                        </div>

                        <div class="col-2">
                            <label class="label-container">唛头 <br /> (Marks)</label>
                        </div>
                        <div class="col-4">
                            <input type="text" v-model="enteredMarks" disabled>
                        </div>

                        <div class="col-2">
                            <label class="label-container">快递公司 <br /> (Courier Name)</label>
                        </div>
                        <div class="col-4">
                            <input type="text" v-model="enteredCourier" disabled>
                        </div>

                        <div class="col-2">
                            <label class="label-container">运单号 <br /> (Parcel Reference)</label>
                        </div>
                        <div class="col-4">
                            <input type="text" class="input-container" v-model="enteredParcelRef" disabled />
                        </div>


                        <div class="col-2">
                            <label class="label-container">品名 <br /> (Product Name)</label>
                        </div>
                        <div class="col-4">
                            <input class="input-container" v-model="enteredProductName" disabled />
                        </div>

                        <div class="col-2">
                            <label class="label-container">货值 <br /> (Product Value)</label>
                        </div>
                        <div class="col-4">
                            <input type="number" class="input-container" v-model="enteredProductValue" disabled />
                        </div>
                        <div class="col-2">
                            <label class="label-container">取货方式 <br /> (Collect Type)</label>
                        </div>
                        <div class="col-4">
                            <input class="input-container" v-if="enteredCollection === 'Self-Collection'" value="自提 (Self-Collection)" disabled />
                            <input class="input-container" v-if="enteredCollection === 'Delivery'" value="送货 (Delivery)" disabled />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </view-booking>

    <edit-booking v-if="openEdit">
        <template #header>
            <div class="row">
                <div class="col-md-11">
                    <h4 style="font-weight: bold; padding-left: 80px; font-weight: bold;">
                        编辑 {{ enteredInternalRef }} 预约
                    </h4>
                </div>
                <div class="col-md-1">
                    <fa-fa icon="times" class="delete-icon" style="float: right; margin-right: 20px; margin-top: 3px;" @click="closeEdit()"></fa-fa>
                </div>
            </div>
        </template>
        <template #content>
            <div class="row">
                <div class="col-12" style="margin-top: 50px;">
                    <div class="row" style="align-items: center;">
                        <div class="col-2">
                            <label class="label-container">预约日期 <br /> (Booking Date)</label>
                        </div>
                        <div class="col-4">
                            <input type="date" v-model="enteredReceivingDate" disabled>
                        </div>

                        <div class="col-2">
                            <label class="label-container">唛头 <br /> (Marks)</label>
                        </div>
                        <div class="col-4">
                            <input type="text" v-model="enteredMarks" disabled>
                        </div>

                        <div class="col-2">
                            <label class="label-container">快递公司 <br /> (Courier Name)</label>
                        </div>
                        <div class="col-4">
                            <select class="select-container" v-model="enteredCourier">
                                <option v-for="courier in courierList" :value="courier.text" :key="courier.text">{{ courier.text }}</option>
                            </select>
                        </div>

                        <div class="col-2">
                            <label class="label-container">运单号 <br /> (Parcel Reference)</label>
                        </div>
                        <div class="col-4">
                            <input type="text" class="input-container" v-model="enteredParcelRef" />
                        </div>


                        <div class="col-2">
                            <label class="label-container">品名 <br /> (Product Name)</label>
                        </div>
                        <div class="col-4">
                            <input class="input-container" v-model="enteredProductName" />
                        </div>

                        <div class="col-2">
                            <label class="label-container">货值 <br /> (Product Value)</label>
                        </div>
                        <div class="col-4">
                            <input type="number" min="0" class="input-container" v-model="enteredProductValue" :disabled="disabled" />
                            <!--<input type="number" min="0" class="input-container" v-model="enteredProductValue" v-else />-->
                        </div>
                        <div class="col-2">
                            <label class="label-container">取货方式 <br /> (Collect Type)</label>
                        </div>
                        <div class="col-4">
                            <select class="select-container" v-model="enteredCollection">
                                <option value="Self-Collection">自提 (Self-Collection)</option>
                                <option value="Delivery">送货 (Delivery)</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-12" style="text-align: center; margin-top: 50px; margin-bottom: 50px;">
                    <DxButton2 icon="save"
                               text="保存"
                               type="success"
                               @click="saveBooking" />
                </div>
            </div>
        </template>
    </edit-booking>

    <edit-booking v-if="openEditOrder">
        <template #header>
            <div class="row">
                <div class="col-md-11">
                    <h4 style="font-weight: bold; padding-left: 80px; font-weight: bold;">
                        编辑 {{ enteredInternalRef }} 订单
                    </h4>
                </div>
                <div class="col-md-1">
                    <fa-fa icon="times" class="delete-icon" style="float: right; margin-right: 20px; margin-top: 3px;" @click="closeEditOrder()"></fa-fa>
                </div>
            </div>
        </template>
        <template #content>
            <div class="row">
                <div class="col-12" style="margin-top: 50px;">
                    <div class="row" style="align-items: center;">
                        <div class="col-2">
                            <label class="label-container">收货日期 <br /> (Receiving Date)</label>
                        </div>
                        <div class="col-4">
                            <input type="date" v-model="enteredReceivingDate" disabled>
                        </div>

                        <div class="col-2">
                            <label class="label-container">唛头 <br /> (Marks)</label>
                        </div>
                        <div class="col-4">
                            <input type="text" v-model="enteredMarks" disabled>
                        </div>

                        <div class="col-2">
                            <label class="label-container">快递公司 <br /> (Courier Name)</label>
                        </div>
                        <div class="col-4">
                            <select class="select-container" v-model="enteredCourier">
                                <option v-for="courier in courierList" :value="courier.text" :key="courier.text">{{ courier.text }}</option>
                            </select>
                        </div>

                        <div class="col-2">
                            <label class="label-container">运单号 <br /> (Parcel Reference)</label>
                        </div>
                        <div class="col-4">
                            <input type="text" class="input-container" v-model="enteredParcelRef" />
                        </div>


                        <div class="col-2">
                            <label class="label-container">品名 <br /> (Product Name)</label>
                        </div>
                        <div class="col-4">
                            <input class="input-container" v-model="enteredProductName" />
                        </div>

                        <div class="col-2">
                            <label class="label-container">货值 <br /> (Product Value)</label>
                        </div>
                        <div class="col-4">
                            <input type="number" min="0" class="input-container" v-model="enteredProductValue" :disabled="disabled" />
                            <!--<input type="number" min="0" class="input-container" v-model="enteredProductValue" v-else />-->
                        </div>
                        <div class="col-2">
                            <label class="label-container">取货方式 <br /> (Collect Type)</label>
                        </div>
                        <div class="col-4">
                            <select class="select-container" v-model="enteredCollection">
                                <option value="Self-Collection">自提 (Self-Collection)</option>
                                <option value="Delivery">送货 (Delivery)</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-12" style="text-align: center; margin-top: 50px; margin-bottom: 50px;">
                    <DxButton2 icon="save"
                               text="保存"
                               type="success"
                               @click="saveOrder" />
                </div>
            </div>
        </template>
    </edit-booking>
    <!--<parcel-detail v-if="parcelDialog">
        <template #header>
            <div class="row">
                <div class="col-md-11">
                    <h4 style="font-weight: bold; padding-left: 80px;">
                        <span v-if="selectedCourier !== null && selectedCourier !== ''">{{ selectedCourier }} / </span>{{ enteredInternalRef }}
                    </h4>
                </div>
                <div class="col-md-1">
                    <fa-fa icon="times" class="delete-icon" style="float: right; margin-right: 20px;" @click="closeParcel()"></fa-fa>
                </div>
            </div>
        </template>
        <template #content>
            <div class="row">
                <div class="col-12" style="margin-top: 20px;">
                    <div class="row" style="align-items: center; text-align: center;">
                        <div class="col-12">
                            <div style="float: left;">
                                <label style="text-align: center; font-size: 20px; font-weight: bold; margin-right: 50px;">运单信息</label>
                            </div>
                        </div>

                        <div class="col-1">
                            <label class="label-container">快递公司 <br /> (Courier Name)</label>
                        </div>
                        <div class="col-3">
                            <input class="input-container" v-model="selectedCourier" disabled />
                        </div>

                        <div class="col-1">
                            <label class="label-container">运单号 <br /> (Parcel Reference)</label>
                        </div>
                        <div class="col-7">
                            <textarea type="text" class="input-container" v-model="enteredParcelRef" style="padding-top: 6px;" disabled />
                        </div>

                        <div class="col-1">
                            <label class="label-container">唛头 <br /> (Marks)</label>
                        </div>
                        <div class="col-3">
                            <input class="input-container" v-model="enteredMarks" disabled />
                        </div>

                        <div class="col-1">
                            <label class="label-container">库位 <br /> (Location)</label>
                        </div>
                        <div class="col-3">
                            <input class="input-container" v-model="enteredLocation" disabled />
                        </div>

                        <div class="col-1">
                            <label class="label-container">品名 <br /> (Product Name)</label>
                        </div>
                        <div class="col-3">
                            <input class="input-container" v-model="enteredProductName" disabled />
                        </div>

                        <div class="col-1">
                            <label class="label-container">货值 <br /> (Product Value)</label>
                        </div>
                        <div class="col-3">
                            <input type="number" min="0" class="input-container" v-model="enteredProductValue" style="text-align: right" disabled />
                        </div>
                    </div>
                </div>

                <div class="col-12" style="margin-top: 30px;">
                    <div class="row" style="align-items: center; text-align: center;">
                        <div class="col-12">
                            <div style="float: left;">
                                <label style="text-align: center; font-size: 20px; font-weight: bold; margin-right: 50px;">费用信息</label>
                            </div>
                        </div>

                        <div class="col-1">
                            <label class="label-container">预约日期 <br /> (Booking Date)</label>
                        </div>
                        <div class="col-3">
                            <input type="date" class="input-container" v-model="enteredReceivingDate" disabled>
                        </div>

                        <div class="col-1">
                            <label class="label-container">编号 <br /> (Internal Reference)</label>
                        </div>
                        <div class="col-7">
                            <input type="text" class="input-container" v-model="enteredInternalRef" disabled>
                        </div>

                        <div class="col-1">
                            <label class="label-container">总重量 <br /> (Weight)</label>
                        </div>
                        <div class="col-3">
                            <input type="number" min="0" class="input-container" v-model="enteredWeight" style="text-align: right" disabled>
                        </div>

                        <div class="col-1">
                            <label class="label-container">目的地港口 <br /> (Destination Port)</label>
                        </div>
                        <div class="col-2">
                            <input type="text" class="input-container" v-if="selectedPOD === 'singapore'" value="新加坡" disabled>
                            <input type="text" class="input-container" v-if="selectedPOD === 'malaysia'" value="马来西亚" disabled>
                            <input type="text" class="input-container" v-if="selectedPOD !== 'malaysia' && selectedPOD !== 'singapore'" disabled>
                        </div>

                        <div class="col-1">
                            <label class="label-container">货运方式 <br /> (Freight Mode)</label>
                        </div>
                        <div class="col-2">
                            <input type="text" class="input-container" v-if="selectedFreight === 'sea'" value="海运" disabled>
                            <input type="text" class="input-container" v-if="selectedFreight === 'air'" value="空运" disabled>
                            <input type="text" class="input-container" v-if="selectedFreight !== 'sea' && selectedFreight !== 'air'" disabled>
                        </div>

                        <div class="col-2" style="text-align: center;">
                            <button style="font-size: 14px; padding: 6px 16px; border-radius: 4px; border: 1px solid #ddd; vertical-align: middle; margin-top: 20px;" @click="toggleSensitive" :class="selectedSensitive ? 'sensitive' : 'insensitive'">
                                敏感货物
                            </button>
                        </div>

                        <div class="col-1">
                            <label class="label-container">备注 <br /> (Remark)</label>
                        </div>
                        <div class="col-11">
                            <textarea rows="2" style="height: 80px; width: 100%; padding-top: 6px;" type="text" id="input-field" class="input-container" v-model="enteredRemark" disabled />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </parcel-detail>-->

    <div class="main-page">
        <div class="main-container">
            <div class="row" style="margin: 0;">
                <div class="col-6" style="padding: 10px; font-size: 15px; font-weight: bold; text-align: left; margin: 0">
                    唛头 (Marks) : {{ this.enteredMarks }}
                </div>
                <div class="col-6" style="text-align: right;">
                    <DxButton2 icon="refresh"
                               text="刷新"
                               style="float: right; margin: 5px;"
                               @click="refreshGrid" />
                </div>
            </div>


            <div class="row">
                <DxDataGrid id="gridContainer"
                            :ref="dataGridRefName"
                            :data-source="parcelList"
                            :hover-state-enabled="true"
                            :word-wrap-enabled="true"
                            :column-auto-width="true"
                            :row-alternation-enabled="true"
                            :remote-operations="true"
                            :show-borders="true">
                    <DxSearchPanel :visible="true"
                                   :width="240"
                                   placeholder="搜索 (Search...)" />
                    <DxFilterRow :visible="true"
                                 :showOperationChooser="false"
                                 :apply-filter="currentFilter" />

                    <DxColumn data-field="receivingDate"
                              caption="日期 (Date)"
                              format="dd/MM/yyyy"
                              data-type="datetime" />

                    <!--<DxColumn data-field="internalReference"
    caption="编号 (Internal Ref)" />-->

                    <DxColumn data-field="parcelReference"
                              max-width="620"
                              css-class="WrappedColumnClass"
                              caption="运单号 (Parcel Ref)" />

                    <DxColumn data-field="courierName"
                              caption="快递公司 (Courier)" />

                    <DxColumn data-field="productName"
                              caption="品名 (Product Name)" />

                    <!--<DxColumn data-field="totalQuantity"
    caption="预约数量 (Booking Quantity)" />-->

                    <DxColumn data-field="totalQuantity"
                              caption="货物数量 (Cargo Quantity)" />



                    <DxColumn data-field="productValue"
                              caption="货值 (￥)" />

                    <DxColumn data-field="GST"
                              alignment="right"
                              :calculate-display-value="setGST"
                              caption="GST (SGD)" />

                    <DxColumn data-field="billM3"
                              caption="总体积 (Total m³)" />

                    <DxColumn data-field="shippingfee"
                              :calculate-display-value="setShipping"
                              caption="运费 (SGD)" />

                    <!--<DxColumn data-field="paymentAmount"
              :calculate-display-value="setAmount"
              caption="支付金额 (Payment Amount)" />-->
                    <!--<DxColumn data-field="pod"
              caption="目的地港口 (Destination Port)">
        <DxLookup :data-source="pod"
                  value-expr="id"
                  display-expr="text" />
    </DxColumn>

    <DxColumn data-field="requestFreightMode"
              caption="货运方式 (Freight Mode)">
        <DxLookup :data-source="freight"
                  value-expr="id"
                  display-expr="text" />
    </DxColumn>-->

                    <DxColumn data-field="cargoStatus"
                              caption="包裹状态 (Parcel Status)">
                        <DxLookup :data-source="status"
                                  value-expr="id"
                                  display-expr="text" />
                    </DxColumn>

                    <DxColumn type="buttons"
                              caption="编辑 (Edit)">
                        <DxButton icon="edit"
                                  text="Edit"
                                  :visible="isEditableBooking"
                                  @click="editBooking" />
                        <DxButton icon="edit"
                                  text="Edit"
                                  :visible="isEditableOrder"
                                  @click="editOrder" />
                    </DxColumn>

                    <DxColumn type="buttons"
                              caption="删除 (Delete)">
                        <DxButton icon="trash"
                                  text="Delete"
                                  :visible="isDeletable"
                                  @click="deleteBooking" />
                    </DxColumn>

                    <DxPaging :page-size="16" />
                    <DxPager :visible="true"
                             :allowed-page-sizes="[16, 20, 50]"
                             :show-page-size-selector="true"
                             :show-info="true"
                             :show-navigation-buttons="true" />

                </DxDataGrid>
            </div>

        </div>
    </div>

    <!--<the-footer></the-footer>-->
</template>

<script>
    import axios from 'axios';
    import {
        DxDataGrid,
        DxColumn,
        DxFilterRow,
        DxSearchPanel,
        DxLookup,
        DxButton,
        DxPager,
        DxPaging,
    } from 'devextreme-vue/data-grid';
    import DxButton2 from 'devextreme-vue/button';
    //import { useToast } from 'vue-toastification';
    import { toast } from 'vue3-toastify';

    //const toast = useToast();
    const status = [{
        id: 'Booking Created', text: '待办中 (Pending)',
    }, {
        id: 'Cargo Received', text: '已收货 (Received)'
    }, {
        id: 'Load Cargo', text: '已装柜 (Load Cargo)'
    }, {
        id: 'Stuffing', text: '已装柜 (Load Cargo)'
    }, {
        id: 'In Transit', text: '在途中 (In Transit)'
    }, {
        id: 'Arrived in Destination', text: '已到达目的地'
    }, {
        id: 'Unstuffing', text: '卸货中 (Unstuffing)'
    }, {
        id: 'Ready for Collection', text: '待提货 (Ready for Collection)'
    }, {
        id: 'Collected/Delivered', text: '已提货/已送达 (Collected/Delivered)'
    }];

    export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxFilterRow,
            DxSearchPanel,
            DxLookup,
            DxButton,
            DxPager,
            DxPaging,
            DxButton2
        },
        data() {
            return {
                parcelDialog: false,

                // loader
                isLoading: false,

                parcelList: [],
                enteredReceivingDate: '',
                enteredInternalRef: '',
                enteredCourier: '',
                enteredParcelRef: '',
                enteredMarks: '',
                enteredProductName: '',
                enteredProductValue: '',
                enteredCollection: '',

                courierList: [],
                openEdit: false,
                openEditOrder: false,
                openView: false,
                status,
                bookingId: 0,
                orderId: 0,
                disabled: false
            }
        },
        async mounted() {
            this.enteredMarks = this.$store.state.marks
            const params = new URLSearchParams();
            params.append('Marks', this.enteredMarks);

            axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcel?' + params.toString())
                .then(response => {
                    this.parcelList = response.data.data;
                });

            await axios.get('https://amcon.express/booxapi/api/v2/selfService/courierLookup')
                .then(response => {
                    this.courierList = response.data.data
                });

            document.title = "包裹追踪"
        },
        methods: {
            editBooking(e) {
                this.disabled = false;
                this.isLoading = true;
                this.openEdit = true;
                const data = e.row && e.row.data;
                const id = data && data.id;
                this.bookingId = id;
                this.$store.commit('setSelectedBooking', id)

                const params = new URLSearchParams();
                params.append('BookingID', this.$store.state.selectedBooking);

                axios.post('https://amcon.express/booxapi/api/v2/selfService/getBookingDetail?' + params.toString())
                    .then(response => {
                        this.isLoading = false;
                        this.selectedBookingList = response.data.result;

                        this.enteredReceivingDate = this.selectedBookingList.bookingDate.substr(0, 10)
                        this.enteredInternalRef = this.selectedBookingList.internalReference
                        this.enteredCourier = this.selectedBookingList.courierName
                        this.enteredParcelRef = this.selectedBookingList.parcelReference
                        this.enteredProductValue = this.selectedBookingList.productValue
                        this.enteredMarks = this.selectedBookingList.marks
                        this.enteredProductName = this.selectedBookingList.productName
                        this.enteredCollection = this.selectedBookingList.collectType

                        if (this.selectedBookingList.productValue > 0) {
                            this.disabled = true;
                        }
                    });
            },
            closeEdit() {
                this.openEdit = false
            },
            editOrder(e) {
                this.disabled = false;
                this.isLoading = true;
                this.openEditOrder = true;
                const data = e.row && e.row.data;
                const id = data && data.id;
                this.orderId = id;
                const params = new URLSearchParams();
                params.append('OrderID', id);

                axios.post('https://amcon.express/booxapi/api/v2/selfService/getOrderDetail?' + params.toString())
                    .then(response => {
                        this.isLoading = false;
                        this.selectedOrder = response.data.result;

                        this.enteredReceivingDate = this.selectedOrder.receivingDate.substr(0, 10)
                        this.enteredInternalRef = this.selectedOrder.internalReference
                        this.enteredCourier = this.selectedOrder.courierName
                        this.enteredParcelRef = this.selectedOrder.parcelReference
                        this.enteredProductValue = this.selectedOrder.productValue
                        this.enteredMarks = this.selectedOrder.marks
                        this.enteredProductName = this.selectedOrder.productName
                        this.enteredCollection = this.selectedOrder.collectType

                        if (this.selectedOrder.productValue > 0) {
                            this.disabled = true;
                        }
                    });
            },
            closeEditOrder() {
                this.openEditOrder = false
            },
            isEditableBooking(e) {
                const rowData = e.row.data;

                if (rowData.totalQuantity === 0) {
                    return true;
                }
                else {
                    return false;
                }
            },
            isEditableOrder(e) {
                const rowData = e.row.data;

                if (rowData.totalQuantity > 0) {
                    return true;
                }
                else {
                    return false;
                }
            },
            isDeletable(e) {
                const rowData = e.row.data;

                if (rowData.cargoStatus === 'Booking Created') {
                    return true;
                }
                else {
                    return false;
                }
            },
            saveBooking() {
                this.isLoading = true;
                axios.post('https://amcon.express/booxapi/api/v2/selfService/saveBooking', {
                    bookingId: this.bookingId,
                    courierName: this.enteredCourier,
                    parcelReference: this.enteredParcelRef,
                    productName: this.enteredProductName,
                    productValue: this.enteredProductValue,
                    collectType: this.enteredCollection,
                })
                    .then(response => {
                        const success = response.data["success"]
                        const msg = response.data["msg"]

                        if (success === true) {
                            this.$swal.fire({
                                title: "保存成功",
                                text: "Booking Saved Successfully",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1500
                            });
                            this.isLoading = false;

                            const params = new URLSearchParams();
                            params.append('Marks', this.enteredMarks);

                            axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcel?' + params.toString())
                                .then(response => {
                                    this.parcelList = response.data.data;
                                });

                            this.openEdit = false;
                        }
                        else {
                            this.isLoading = false;
                            toast.error(msg, {
                                position: 'top-center',
                                timeout: 2000,
                            });
                            this.openEdit = false;
                        }
                    })
                    .catch(err => {
                        this.isLoading = false;
                        console.log(err)
                    })
            },
            deleteBooking(e) {
                const data = e.row && e.row.data;
                const id = data && data.id;
                const ref = data && data.parcelReference;

                this.$swal.fire({
                    title: "确定删除运单" + ref + "?",
                    text: "Confirm Delete Parcel " + ref + "?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "确定 (Confirm)",
                    cancelButtonText: "取消 (Cancel)"
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('https://amcon.express/booxapi/api/v2/selfService/deleteBooking', {
                            bookingId: id,
                        })
                            .then(response => {
                                const success = response.data["success"]
                                const msg = response.data["msg"]
                                if (success === true) {
                                    this.$swal.fire({
                                        title: "已删除运单" + ref,
                                        text: ref + " Booking Deleted",
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 1500
                                    });

                                    const params = new URLSearchParams();
                                    params.append('Marks', this.enteredMarks);

                                    axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcel?' + params.toString())
                                        .then(response => {
                                            this.parcelList = response.data.data;
                                        });
                                }
                                else {
                                    this.$swal.fire({
                                        title: msg,
                                        text: "删除" + ref + "失败",
                                        icon: "error"
                                    });
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })

                    }
                });

                
            },
            saveOrder() {
                this.isLoading = true;
                axios.post('https://amcon.express/booxapi/api/v2/selfService/saveOrder', {
                    customerOrderId: this.orderId,
                    courierName: this.enteredCourier,
                    parcelReference: this.enteredParcelRef,
                    productName: this.enteredProductName,
                    productValue: this.enteredProductValue,
                    collectType: this.enteredCollection,
                })
                    .then(response => {
                        const success = response.data["success"]
                        const msg = response.data["msg"]

                        if (success === true) {
                            this.$swal.fire({
                                title: "保存成功",
                                text: "Order Saved Successfully",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1500
                            });
                            this.isLoading = false;

                            const params = new URLSearchParams();
                            params.append('Marks', this.enteredMarks);

                            axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcel?' + params.toString())
                                .then(response => {
                                    this.parcelList = response.data.data;
                                });

                            this.openEditOrder = false;
                        }
                        else {
                            this.isLoading = false;
                            toast.error(msg, {
                                position: 'top-center',
                                timeout: 2000,
                            });
                            this.openEditOrder = false;
                        }
                    })
                    .catch(err => {
                        this.isLoading = false;
                        console.log(err)
                    })
            },
            refreshGrid() {
                const params = new URLSearchParams();
                params.append('Marks', this.enteredMarks);

                axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcel?' + params.toString())
                    .then(response => {
                        this.parcelList = response.data.data;
                    });
            },
            setGST(rowData) {
                //var totalAmt = rowData.paymentAmount / rowData.exchangeRate
                var gst = rowData.gst / rowData.exchangeRate

                if (gst !== null) {
                    return 'SGD ' + gst.toFixed(2);
                }
                else {
                    return 'SGD ' + (0).toFixed(2)
                }

            },
            setShipping(rowData) {
                //var totalAmt = rowData.paymentAmount / rowData.exchangeRate
                var shipping = rowData.shippingfee / rowData.exchangeRate

                if (shipping !== null) {
                    return 'SGD ' + shipping.toFixed(2);
                }
                else {
                    return 'SGD ' + (0).toFixed(2)
                }

            },
            setAmount(rowData) {
                //var totalAmt = rowData.paymentAmount / rowData.exchangeRate
                var totalAmt = rowData.paymentAmount / rowData.exchangeRate

                if (totalAmt !== null) {
                    return 'SGD ' + totalAmt.toFixed(2);
                }
                else {
                    return 'SGD ' + (0).toFixed(2)
                }

            },
        },
    };
</script>

<style scoped>
    input[type=text]:disabled {
        height: 35px;
        text-align: left;
        padding-left: 10px;
        width: 100%;
        margin-top: 30px;
        outline: none;
        font-size: 13px;
    }

    input[type=date]:disabled {
        height: 35px;
        text-align: left;
        padding-left: 10px;
        width: 100%;
        margin-top: 30px;
        outline: none;
        font-size: 13px;
    }

    .input-container, .select-container {
        height: 35px;
        text-align: left;
        padding-left: 10px;
        width: 100%;
        margin-top: 30px;
        outline: none;
    }

        .input-container:focus, .input-container:hover {
            border: 1px solid #B1CCE3;
        }

    .WrappedColumnClass {
        white-space: normal;
        word-wrap: break-word;
    }

    .dx-autocomplete {
        width: 100%;
        height: 35px;
        text-align: left;
        font-size: 16px;
        font-weight: bolder;
        outline-color: black;
        padding-left: 10px;
        margin-top: 30px;
        margin-bottom: 5px;
        border-radius: 0px;
        font-size: 13px;
    }

    .label-container {
        margin-top: 30px;
        text-align: center;
        width: 100%;
    }

    /* bootstrap */
    .row {
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 768px) {
        button {
            margin-bottom: 5px;
            margin-top: 5px;
        }

        img {
            margin: 20px 0px;
        }
    }
</style>