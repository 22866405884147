<template>
    <the-loader :isLoading="isLoading"></the-loader>
    <div class="limiter">

        <div class="container-login100">
            <div class="wrap-login100">
                <div class="login100-pic js-tilt" data-tilt>
                    <img src="../../images/amcon_norm_logo.png" alt="IMG">
                </div>

                <form class="login100-form validate-form" @submit.prevent="login">
                    <span class="login100-form-title">
                        <b>会员登录</b>
                    </span>

                    <div style="margin-bottom: 10px;">
                        <div class="wrap-input100 validate-input" style="margin-bottom: 0px;">
                            <input class="input100" type="text" name="marks" placeholder="唛头, Marks" v-model="marks" @input="upperCase">
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <fa-fa icon="box"></fa-fa>
                            </span>
                        </div>
                        <label style="margin-left: 20px; color: red; font-size: 13px;" v-if="invalidMarksMsg != ''">*{{ invalidMarksMsg }}</label>
                    </div>
                    
                    <div>
                        <div class="wrap-input100 validate-input" style="margin-bottom: 0px;">
                            <input class="input100" type="text" name="contact" placeholder="联络号码, Contact No" v-model="contact">
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <fa-fa icon="phone"></fa-fa>
                            </span>
                        </div>
                        <label style="margin-left: 20px; color: red; font-size: 13px; width: 270px;" v-if="failedMsg != ''">*{{ failedMsg }}</label>
                    </div>
                    

                    <div class="container-login100-form-btn">
                        <button type="submit" class="login100-form-btn"><b>登录</b></button>
                    </div>
                    <label class="registerLink" @click="registerCust">注册会员</label>
                </form>
            </div>

            <div class="wrap-calculation100" style="padding: 5px 80px 5px 80px;">
                <div class="text-center p-t-12">
                </div>
                <div class="login100-form validate-form">
                    <span class="calc100-form-title">
                        <b>运费查询</b>
                    </span>

                    <div class="row" style="color: #ABB4BB; margin: 20px 0">
                        <div class="col-md-6">
                            <label style="padding-bottom: 5px;">*请输入包裹毛重为公斤 (Kg)。</label>
                            <br />
                            <label>*请输入尺寸重为厘米 (cm)。</label>
                        </div>
                        <div class="col-md-6">
                            <label>*以下报价以最平的运输方法计算, 价格仅供参考。</label>
                        </div>
                    </div>

                    <div class="row" style="margin: 5px 0">
                        <div class="col-md-4">
                            <!--<label>包裹毛重</label>-->
                            <div class="wrap-calc-input100 validate-calc-input">
                                <input class="calc-input100" type="text" placeholder="包裹毛重 (kg)" v-model="enteredWeight">
                                <span class="focus-calc-input100"></span>
                                <span class="symbol-calc-input100">
                                    <fa-fa icon="weight"></fa-fa>
                                </span>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <!--<label>收货方式</label>--> <!--拼团/配送-->
                            <div class="wrap-calc-input100 validate-calc-input">
                                <select class="calc-input100" type="text" value="" v-model="collectType">
                                    <option value="">请选收货方式</option>
                                    <option value="group">拼团</option>
                                    <option value="delivery">配送</option>
                                </select>
                                <span class="focus-calc-input100"></span>
                                <span class="symbol-calc-input100">
                                    <fa-fa icon="truck-fast"></fa-fa>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin: 5px 0">

                        <div class="col-md-8">
                            <!--<div class="col-md-12">
                                <label>包裹尺寸</label>
                            </div>-->
                            <div class="col-md-4" style="display: inline-flex; align-items: baseline;">
                                <div class="wrap-calc-input100 validate-calc-input">
                                    <input class="calc-input100" type="number" name="length" placeholder="长度 (cm)" v-model="enteredLength" @focus="$event.target.select()">
                                    <span class="focus-calc-input100"></span>
                                    <span class="symbol-calc-input100">
                                        <fa-fa icon="ruler"></fa-fa>
                                    </span>
                                </div>
                                <fa-fa icon="times" style="padding-right: 10px;"></fa-fa>
                            </div>

                            <div class="col-md-4" style="display: inline-flex; align-items: baseline">
                                <div class="wrap-calc-input100 validate-calc-input">
                                    <input class="calc-input100" type="number" name="width" placeholder="宽度 (cm)" v-model="enteredWidth" @focus="$event.target.select()">
                                    <span class="focus-calc-input100"></span>
                                    <span class="symbol-calc-input100">
                                        <fa-fa icon="ruler-horizontal"></fa-fa>
                                    </span>
                                </div>
                                <fa-fa icon="times" style="padding-right: 10px;"></fa-fa>
                            </div>
                            <div class="col-md-4" style="display: inline-flex; align-items: baseline">
                                <div class="wrap-calc-input100 validate-calc-input">
                                    <input class="calc-input100" type="number" name="height" placeholder="高度 (cm)" v-model="enteredHeight" @focus="$event.target.select()">
                                    <span class="focus-calc-input100"></span>
                                    <span class="symbol-calc-input100">
                                        <fa-fa icon="ruler-vertical"></fa-fa>
                                    </span>
                                </div>
                                <!--<fa-fa icon="times" style="padding-right: 10px;"></fa-fa>-->
                            </div>

                        </div>
                        <div class="col-md-4">
                            <!--<div class="col-md-12">
                                <label>件数</label>
                            </div>-->
                            <div class="col-md-12" style="display: inline-flex; align-items: baseline">
                                <div class="wrap-calc-input100 validate-calc-input">
                                    <input class="calc-input100" type="number" name="quantity" placeholder="件数" v-model="enteredQuantity" @focus="$event.target.select()">
                                    <span class="focus-calc-input100"></span>
                                    <span class="symbol-calc-input100">
                                        <fa-fa icon="calculator"></fa-fa>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row" style="align-items: center; margin: 15px 0;">
                        <div class="col-md-1">
                            预估运费
                            <br />
                            <label v-if="collectType === 'delivery'">配送费用</label>
                        </div>
                        <div class="col-md-3">
                            <label style="color: #FB7401; font-weight: bold">{{ shippingFee }}</label> <b>(SGD)</b>
                            <br />
                            <label style="color: #FB7401; font-weight: bold" v-if="collectType === 'delivery'">{{ deliveryFee }}</label> <b v-if="collectType === 'delivery'">(SGD)</b>
                        </div>
                        <div class="col-md-8">
                            <div class="container-calc100-form-btn">
                                <button class="calc100-form-btn" @click="calculate"><b>查询</b></button>
                            </div>
                        </div>
                    </div>




                    <div class="text-center p-t-12">
                    </div>

                    <!--<div class="text-center p-t-136">
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        mounted() {
            document.title = "会员登录"
        },
        data() {
            return {
                // Login
                marks: '',
                contact: '',
                custId: 0,
                custMarksList: [],

                // Calculation
                enteredWeight: null,
                enteredLength: null,
                enteredWidth: null,
                enteredHeight: null,
                enteredQuantity: null,
                totalM3: 0,
                shippingFee: (0).toFixed(2),
                deliveryFee: (40).toFixed(2),

                isLoading: false,
                invalidMarksMsg: "",
                failedMsg: "",
                collectType: ""
            }
        },
        methods: {
            upperCase() {
                this.marks = this.marks.toUpperCase();
            },
            login() {
                this.isLoading = true;
                axios.post('https://amcon.express/booxapi/api/v2/selfService/login', {
                    marks: this.marks,
                    phoneNo: this.contact
                }).then(response => {
                    const success = response.data["success"]
                    const msg = response.data["msg"]

                    if (success == true) {
                        const params = new URLSearchParams();
                        params.append('Marks', this.marks);

                        axios.get('https://amcon.express/booxapi/api/v2/selfService/getCustIdByMarks?' + params)
                            .then(response => {
                                console.log(response)
                                this.isLoading = false;
                                this.custId = response.data.data[0].custId;
                                console.log(response)
                                this.$store.commit('setCustId', this.custId)
                                this.$store.commit('setMarks', this.marks)
                                console.log(this.$store.state.custId)
                                this.$router.push('/selfsvcMenu')
                            })
                            .catch(err => {
                                this.isLoading = false;
                                console.log(err)
                            })
                    }
                    else {
                        this.isLoading = false;
                        if (msg === "唛头不存在 (Invalid Marks)") {
                            this.failedMsg = ""
                            this.invalidMarksMsg = msg
                        }
                        else {
                            this.invalidMarksMsg = ""
                            this.failedMsg = msg
                        }
                        console.log(this.invalidMarksMsg)
                    }

                })
                    .catch(err => {
                        this.isLoading = false;
                        console.log(err)
                    })
            },
            registerCust() {
                this.$router.push('/registerCustomer')
            },
            calculate() {
                var charge = 0;
                this.totalM3 = (this.enteredLength * this.enteredWidth * this.enteredHeight / 1000000);
                this.totalM3 = this.totalM3 >= 0.01 ? this.totalM3 : '0.01';

                if (this.enteredWeight > 0) {
                    if (this.enteredWeight / 250 > this.totalM3) {
                        this.totalM3 = this.enteredWeight / 250
                    }
                }

                if (this.totalM3 < 0.01) {
                    this.totalM3 = 0.01
                }

                axios.post('https://amcon.express/booxapi/api/v2/selfService/getEGDRate')
                    .then(response => {
                        charge = response.data.result.currencyRate
                        console.log(this.totalM3)
                        this.totalM3 = this.totalM3 * this.enteredQuantity
                        this.shippingFee = (this.totalM3.toFixed(2) * charge).toFixed(2)
                    })
            }
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: Poppins-Regular;
        src: url('../../fonts/poppins/Poppins-Regular.ttf');
    }

    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    body, html {
        height: 100%;
        font-family: Poppins-Regular, sans-serif;
    }

    .registerLink {
        color: #D15829;
        font-size: 13px;
        float: right;
        padding-top: 15px;
        padding-right: 10px;
        cursor: pointer;
    }

        .registerLink:hover {
            color: black;
        }

    .limiter {
        width: 100%;
        margin: 0 auto;
    }

    .container-login100 {
        width: 100%;
        min-height: 100vh;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 15px;
        background: -webkit-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: -o-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: -moz-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
    }

    .wrap-login100 {
        width: 960px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /*padding: 177px 130px 33px 95px;*/
        padding: 50px 80px 30px 80px;
        /*margin-right: 16px;*/
    }

    .wrap-calculation100 {
        width: 960px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /*padding: 177px 130px 33px 95px;*/
        padding: 40px 80px 40px 80px;
        margin-top: 5px;
    }

    @media (max-width: 992px) {
        .wrap-login100 {
            padding: 177px 90px 33px 85px;
        }
    }

    @media (max-width: 768px) {
        .wrap-login100 {
            padding: 100px 80px 33px 80px;
        }
    }

    @media (max-width: 576px) {
        .wrap-login100 {
            padding: 100px 15px 33px 15px;
        }
    }

    .text-center {
        text-align: center;
    }

    .p-t-12 {
        padding-top: 12px;
    }

    .p-t-136 {
        padding-top: 136px;
    }

    /* Image */
    .login100-pic {
        width: 316px;
        margin-top: 70px;
    }

        .login100-pic img {
            max-width: 100%;
        }

    /* Input */
    input {
        outline: none;
        border: none;
    }

        input::-webkit-input-placeholder {
            color: #999999;
        }

        input:-moz-placeholder {
            color: #999999;
        }

        input::-moz-placeholder {
            color: #999999;
        }

        input:-ms-input-placeholder {
            color: #999999;
        }

    .validate-input {
        position: relative;
    }

    .wrap-input100 {
        position: relative;
        width: 100%;
        z-index: 1;
        margin-bottom: 10px;
    }

    .input100 {
        font-family: Poppins-Medium;
        font-size: 15px;
        line-height: 1.5;
        color: #666666;
        display: block;
        width: 100%;
        background: #e6e6e6;
        height: 50px;
        border-radius: 25px;
        padding: 0 30px 0 68px;
    }

    .symbol-input100 {
        font-size: 15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        position: absolute;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-left: 35px;
        pointer-events: none;
        color: #666666;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
    }

    .input100:focus + .focus-input100 {
        -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
        animation: anim-shadow 0.5s ease-in-out forwards;
    }

        .input100:focus + .focus-input100 + .symbol-input100 {
            color: #AC3121; /* #57b846 */
            padding-left: 28px;
        }

    /* Calculation Input */
    .focus-calc-input100 {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 0px 0px;
        color: #AC3121;
    }

    .validate-calc-input {
        position: relative;
    }

    .wrap-calc-input100 {
        position: relative;
        width: 100%;
        z-index: 1;
        margin-bottom: 10px;
        padding-right: 10px;
    }

    .calc-input100 {
        font-family: Poppins-Medium;
        font-size: 15px;
        line-height: 1.5;
        color: #666666;
        display: block;
        width: 100%;
        background: white;
        height: 50px;
        padding: 0 20px 0 40px;
        border-radius: 5px;
        border: #e6e6e6 1px solid;
    }

    .symbol-calc-input100 {
        font-size: 15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        position: absolute;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-left: 10px;
        pointer-events: none;
        color: #666666;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
    }

    .calc-input100:focus + .focus-calc-input100 + .symbol-calc-input100 {
        color: #AC3121; /* #57b846 */
    }

    .focus-calc-input100 {
        outline: #AC3121;
        display: block;
        position: absolute;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 0px 0px;
        color: #AC3121;
    }

    /* Login Container */
    .container-login100-form-btn {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 20px;
    }

    .container-calc100-form-btn {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
    }

    /* Login Button */
    button {
        outline: none !important;
        border: none;
        background: transparent;
    }

        button:hover {
            cursor: pointer;
        }
    .login100-form-btn {
        font-family: Montserrat-Bold;
        font-size: 15px;
        line-height: 1.5;
        color: #fff;
        text-transform: uppercase;
        width: 100%;
        height: 50px;
        border-radius: 25px;
        background: -webkit-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: -o-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: -moz-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        background: linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 25px;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
        text-decoration: none;
    }

        .login100-form-btn:hover {
            background: -webkit-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
            background: #333333;
        }

    .calc100-form-btn {
        font-family: Montserrat-Bold;
        font-size: 15px;
        line-height: 1.5;
        color: #fff;
        text-transform: uppercase;
        width: 23%;
        height: 45px;
        border-radius: 5px;
        background: #015697;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 25px;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
        text-decoration: none;
    }

        .calc100-form-btn:hover {
            background: -webkit-linear-gradient(-135deg, #AC3121, #D15829, #F17A30);
            background: #333333;
        }

    .login100-form-title {
        font-family: Poppins-Bold;
        font-size: 24px;
        color: #333333;
        line-height: 1.2;
        text-align: center;
        width: 100%;
        display: block;
        padding-bottom: 20px;
    }

    .calc100-form-title {
        font-family: Poppins-Bold;
        font-size: 20px;
        color: #054C08;
        line-height: 1.2;
        text-align: left;
        width: 100%;
        display: block;
        padding-top: 15px;
    }
</style>