<template>
    <div>
        <main>
            <router-view></router-view>
        </main>
    </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

    * {
        margin: 0;
    }
    html {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        background-color: white;
        font-weight: bold;
    }

    .dx-datagrid .dx-row {
        border: none;
    }

        .dx-datagrid .dx-row:hover {
            background-color: #F5F5F5
        }

    .main-page {
        width: 100%;
        height: 90%;
        text-align: center;
        overflow: auto;
    }

    .main-container {
        width: 100%;
        position: absolute;
        overflow: auto;
        padding: 10px;
        z-index: -1;
        font-size: 13px;
    }

    /* input */
    .input-container, .select-container {
        width: 97%;
        float: left;
        border: 1px solid #D8D7D7;
        text-align: left;
        outline: none;
        color: black;
        height: 35px;
        font-size: 13px;
        padding: 0px 10px;
        border-radius: 5px;
    }

        .input-container[type=date] {
            height: 25px;
            font-size: 13px;
        }

    .select-container {
        height: 25px;
        color: black;
    }


    .dialog-input-container, .dialog-select-container {
        width: 100%;
        border: 1px solid #D8D7D7;
        text-align: center;
        outline: none;
        color: black;
        height: 30px;
    }

    /* icons */
    .delete-icon {
        padding: 4px 7px;
        background-color: #FF0000;
        border-radius: 20px;
        font-size: 15px;
        color: white;
        cursor: pointer;
    }

        .delete-icon:hover {
            opacity: 0.7;
        }


</style>

<style scoped>
</style>