<template>
    <header>
        <div>
            <div style="cursor: pointer; float: left; margin-top: 3px;">
                <router-link to="/selfsvcMenu"><fa-fa style="font-size: 25px; color: white" icon="house" /></router-link>
            </div>
        </div>
        <div style="color: white; font-weight: bold; text-align: center; font-size: 20px;">
            <span>
                {{ title }}
            </span>
        </div>
    </header>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: false,
            },
        },
    };
</script>


<style scoped>
    header {
        background: -webkit-linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        background: -o-linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        background: -moz-linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        background: linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        padding: 15px;
        align-items: center;
    }
</style>