<template>
    <div></div>
    <dialog open>
        <section>
            <slot></slot>
        </section>
    </dialog>

</template>

<style scoped>
    div {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 10;
    }

    dialog {
        position: fixed;
        z-index: 100;
        border: none;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
        padding: 0;
        margin: 0;
        overflow: auto;
        left: calc(50%);
        top: calc(50%);
        width: 80%;
        transform: translate(-50%, -50%);
        height: 90%;
    }
</style>