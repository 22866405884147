<template>
    <the-header title="创建新预约 (Create Booking)"></the-header>

    <the-loader :isLoading="isLoading"></the-loader>

    <div class="main-page">
        <div class="main-container">
            <div style="padding: 10px; font-size: 15px;">
                唛头 (Marks) : {{ this.enteredMarks }} <br />
                编号 (Internal Reference) : {{ this.enteredInternalRef }} <br />
                预约日期 (Booking Date) : {{ this.enteredReceivingDate }} <br />
                <span style="color: red">*必须填写运单号 (Must enter Parcel Reference)</span>
            </div>

            <div class="row">
                <DxDataGrid id="gridContainer"
                            :ref="dataGridRefName"
                            :data-source="orderList"
                            :word-wrap-enabled="false"
                            :column-auto-width="true"
                            :show-borders="true"
                            :show-row-lines="true"
                            :on-focused-cell-changing="onFocusedCellChanging">

                    <DxKeyboardNavigation :edit-on-key-press="editOnKeyPress"
                                          :enter-key-action="enterKeyAction"
                                          :enter-key-direction="enterKeyDirection" />

                    <DxEditing :allow-updating="true"
                               mode="cell"
                               start-edit-action="click"
                               :select-text-on-edit-start="true" />

                    <DxKeyboardNavigation :edit-on-key-press="editOnKeyPress"
                                          :enter-key-action="enterKeyAction"
                                          :enter-key-direction="enterKeyDirection" />

                    <DxColumn data-field="parcelReference"
                              caption="运单号 (Parcel Reference)" />

                    <DxColumn data-field="courierName"
                              caption="快递公司 (Courier Name)">
                        <DxLookup :data-source="courierData"
                                  value-expr="id"
                                  display-expr="text" />

                    </DxColumn>

                    <DxColumn data-field="productValue"
                              data-type="number"
                              caption="货值 (Product Value) ¥" />

                    <DxColumn data-field="productName"
                              caption="货名 (Product Name)" />

                    <DxColumn data-field="totalQuantity"
                              data-type="number"
                              caption="件数 (Quantity)" />

                    <DxColumn data-field="collectType"
                              caption="取货方式 (Collect Type)">
                        <DxLookup :data-source="collectType"
                                  value-expr="id"
                                  display-expr="text" />
                    </DxColumn>

                    <DxColumn data-field="requestFreightMode"
                              caption="货运方式 (Freight Mode)">
                        <DxLookup :data-source="freightMode"
                                  value-expr="id"
                                  display-expr="text" />
                    </DxColumn>

                </DxDataGrid>
            </div>
            
            <div class="row">
                <div style="text-align: center; width: 100%; margin-top: 10px;">
                    <DxButton icon="plus"
                              text="添加 (Create)"
                              type="success"
                              style="margin-right: 10px; margin-left: 10px;"
                              @click="createOrder" />

                    <DxButton icon="close"
                              text="取消 (Cancel)"
                              type="danger"
                              style="margin-right: 15px;"
                              @click="selfsvcMenu" />
                </div>
            </div>
            
        </div>
    </div>
    <!--<the-footer></the-footer>-->
</template>

<script>
    import axios from 'axios';
    import {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxLookup,
        DxKeyboardNavigation
    } from 'devextreme-vue/data-grid';
    import { DxButton } from 'devextreme-vue/button';
    import { useToast } from 'vue-toastification';
    //import { toast } from 'vue3-toastify';

    const toast = useToast();
    const collectType = [{
        id: 'Self-Collection', text: '自提 (Self-Collect)',
    }, {
            id: 'Delivery', text: '送货 (Delivery)',
        }];

    const freightMode = [{
        id: 'air', text: '空运',
    }, {
        id: 'sea', text: '海运',
    }];

    export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing,
            DxLookup,
            DxKeyboardNavigation,
            DxButton
        },
        data() {
            return {
                // loader
                isLoading: false,

                // set default value
                enteredReceivingDate: new Date(),
                enteredInternalRef: '',
                enteredParcelRef: '',
                enteredRemark: '',
                enteredProductValue: 0,
                enteredProductName: '',
                selectedSensitive: false,
                enteredMarks: '',
                selectedCourier: '',
                enteredWeight: 0,
                selectedFreight: 'sea',
                selectedPOD: 'singapore',
                filteredCouriers: [],
                courierDropdownOpen: false,
                openCourier: false,
                courierList: [],
                groupedLists: {},
                commonCourierList: [],
                orderList: [
                    { parcelReference: '', courierName: '', productValue: 0, productName: '', totalQuantity: 0, collectionType: '', requestFreightMode: '' },
                    { parcelReference: '', courierName: '', productValue: 0, productName: '', totalQuantity: 0, collectionType: '', requestFreightMode: '' },
                    { parcelReference: '', courierName: '', productValue: 0, productName: '', totalQuantity: 0, collectionType: '', requestFreightMode: '' },
                    { parcelReference: '', courierName: '', productValue: 0, productName: '', totalQuantity: 0, collectionType: '', requestFreightMode: '' },
                    { parcelReference: '', courierName: '', productValue: 0, productName: '', totalQuantity: 0, collectionType: '', requestFreightMode: '' },
                    { parcelReference: '', courierName: '', productValue: 0, productName: '', totalQuantity: 0, collectionType: '', requestFreightMode: '' },
                    { parcelReference: '', courierName: '', productValue: 0, productName: '', totalQuantity: 0, collectionType: '', requestFreightMode: '' },
                    { parcelReference: '', courierName: '', productValue: 0, productName: '', totalQuantity: 0, collectionType: '', requestFreightMode: '' },
                    { parcelReference: '', courierName: '', productValue: 0, productName: '', totalQuantity: 0, collectionType: '', requestFreightMode: '' },
                ],

                //devextreme
                editOnKeyPress: true,
                enterKeyDirection: 'row',
                enterKeyAction: 'moveFocus',

                collectType,
                courierData: [],
                freightMode
            };
        },
        async mounted() {
            this.enteredReceivingDate = this.currentDate()
            this.enteredMarks = this.$store.state.marks

            const params = new URLSearchParams();
            params.append('CustId', this.$store.state.custId);

            await axios.post('https://amcon.express/booxapi/api/v2/selfService/getCustomerByCustId?' + params.toString())
                .then(response => {
                    console.log(response.data.result.marks)
                    this.selectedCust = response.data.result;

                    this.enteredLocation = this.selectedCust.defaultLocation
                });

            await axios.get('https://amcon.express/booxapi/api/v2/selfService/courierLookup')
                .then(response => {
                    this.courierData = response.data.data
                });

            await axios.post('https://amcon.express/booxapi/api/v2/selfService/getAutoBookingInternalRef', {
            }).then(response => {
                const success = response.data["success"]
                const msg = response.data["msg"]

                if (success) {
                    this.enteredInternalRef = response.data.result;
                }
                else {
                    alert(msg);
                }
            });
            document.title = "创建新预约"
        },
        methods: {
            selfsvcMenu() {
                this.$router.push('/selfsvcMenu')
            },
            currentDate() {
                const current = new Date();
                const y = String(current.getFullYear())
                const m = String(current.getMonth() + 1).padStart(2, '0')
                const d = String(current.getDate()).padStart(2, '0')

                return [y, m, d].join('-');
            },
            async createOrder() {
                const enterEvent = new KeyboardEvent('keydown', {
                    key: 'Enter',
                    bubbles: true,
                    cancelable: true,
                });

                // Dispatch the event on the document or the specific input element
                await document.dispatchEvent(enterEvent);

                await axios.post('https://amcon.express/booxapi/api/v2/selfService/createBooking', {
                    internalReference: this.enteredInternalRef,
                    marks: this.enteredMarks,
                    customerBookings: this.orderList,
                    custId: this.$store.state.custId
                })
                    .then(response => {
                        const success = response.data["success"]
                        const msg = response.data["msg"]
                        if (success === true) {
                            this.isLoading = false;
                            this.$swal.fire({
                                title: "创建成功",
                                text: "Booking Created Successfully",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1500
                            });

                            this.$router.push('/parcelTracking')

                        }
                        else {
                            toast.error(msg, {
                                position: 'top-right',
                                timeout: 5000,
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        toast.error("货值和件数不能为空\r\nProduct Value and Quantity cannot be empty", {
                            position: 'top-right',
                            timeout: 5000,
                        });
                    })

            },
        }
    };
</script>

<style scoped>
    .main-container {
        font-weight: bold;
        text-align: left;
    }

    .input-container, .select-container {
        height: 30px;
        text-align: left;
        padding-left: 10px;
        width: 100%;
        margin-top: 20px;
    }

    .label-container {
        margin-top: 20px;
    }

    .search-container {
        position: relative;
        display: inline-block;
    }

    .sensitive {
        background-color: #FF0000;
        color: white;
    }

    .insensitive {
        background-color: white;
        color: red;
    }


    /* bootstrap */
    .row {
        margin-left: auto;
        margin-right: auto;
    }


    .dropdown-table {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

        .dropdown-table tr td label {
            padding: 7px 10px;
            cursor: pointer;
        }

        .dropdown-table tr:nth-child(even) {
            background-color: #f2f2f2; /* Alternate color for even rows */
        }

    .close-icon {
        padding: 5px 8px;
        font-size: 15px;
        background-color: #D9534F;
        border-radius: 20px;
        color: white;
        cursor: pointer;
        margin-top: 1px;
    }

        .close-icon:hover {
            background-color: #CE312C;
        }

    .courier-name:hover {
        color: #5282FA
    }

    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        background-color: #fff;
        border: 1px solid #ccc;
        text-align: left;
    }

        .dropdown ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        .dropdown li {
            padding: 8px;
            cursor: pointer;
        }

            .dropdown li:hover {
                background-color: #f0f0f0;
            }


    @media screen and (max-width: 768px) {
        .md-btn {
            writing-mode: vertical-lr;
            display: inline-grid;
            align-items: center;
            clear: both;
        }

        button {
            margin-top: 20px;
            margin: 15px 0px 0px 5px;
        }

        img {
            margin: 20px 0px;
        }
    }
</style>