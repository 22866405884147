<template>
    <div></div>
    <dialog open>
        <header>
            <slot name="header"></slot>
        </header>
        <section>
            <slot></slot>
        </section>
        <footer>
            <slot name="actions"></slot>
        </footer>
    </dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  emits: ['close']
};
</script>

<style scoped>
    div {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 10;
    }

    dialog {
        position: fixed;
        top: 20vh;
        left: 10%;
        width: 80%;
        z-index: 100;
        border: none;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
        padding: 0;
        margin: 0;
        overflow: hidden;
        text-align: center;
        font-weight: bold;
    }

    header {
        background-color: rgb(254, 244, 112, 0.5);
        padding: 10px 0px;
        align-items: center;
    }

    section {
        text-align: center;
        padding-top: 60px;
        padding-bottom: 30px;
        font-size: 12px;
    }

    footer {
        font-size: 12px;
        padding: 1rem;
        margin-bottom: 20px;
        font-weight: normal;
    }

    @media (min-width: 768px) {
        dialog {
            left: calc(50% - 30rem);
            width: 60rem;
        }
    }
</style>