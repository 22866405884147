<template>
    <the-header title="包裹详情 (Parcel Detail)"></the-header>

    <div class="main-page">
        <div class="main-container">
            <div class="row" style="margin-top: 10px; margin-bottom: 10px; font-weight: bold; align-items: center;">
                <div class="col-md-1">
                    <label>签收日期 (Rec Date)</label>
                </div>
                <div class="col-md-2">
                    <input class="input-container" disabled="disabled" v-model="selectedOrder.receivingDate" />
                </div>
                <div class="col-md-1">
                    <label>编号 (Internal Ref)</label>
                </div>
                <div class="col-md-2">
                    <input class="input-container" disabled="disabled" v-model="selectedOrder.internalReference" />
                </div>
                <div class="col-md-1">
                    <label>急件 (Urgent Pkg)</label>
                </div>
                <div class="col-md-2">
                    <input class="input-container" disabled="disabled" v-if="selectedOrder.isUrgentPackage === true" style="color: red" value="是">
                    <input class="input-container" disabled="disabled" v-if="selectedOrder.isUrgentPackage === false" value="否">
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <span class="md-btn" style="float: right">
                        <router-link to="/parcelTracking"><button class="red-btn" style="font-weight: bold; margin-right: 20px">取消 <br /> (Cancel)</button></router-link>
                    </span>
                </div>
            </div>
            <div class="first-form">
                <div class="row" style="margin-top: 15px; align-items: center">
                    <div class="col-md-1">
                        <label>唛头 (Marks)</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" disabled="disabled" v-model="selectedOrder.marks" />
                    </div>
                    <div class="col-md-1">
                        <label>货主 (Shipper)</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" disabled="disabled" v-model="selectedOrder.custName" />
                    </div>
                    <div class="col-md-1">
                        <label>快递公司 (Courier)</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" disabled="disabled" v-model="selectedOrder.courierName" />
                    </div>
                    <div class="col-md-1">
                        <label>运单号 (Parcel Ref)</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" disabled="disabled" v-model="selectedOrder.parcelReference" />
                    </div>


                </div>
                <div class="row" style="margin-top: 10px; margin-bottom: 15px; align-items: center">
                    <div class="col-md-1">
                        <label>代付运费 (Courier Fee)</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" disabled="disabled" v-model="selectedOrder.courierFee" />
                    </div>
                    <div class="col-md-1">
                        <label>提货费 (Pickup Fee)</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" disabled="disabled" v-model="selectedOrder.pickupFee" />
                    </div>
                    <div class="col-md-1">
                        <label>叉车费 (F/Lift Fee)</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" disabled="disabled" v-model="selectedOrder.forkliftFee" />
                    </div>
                    <div class="col-md-1">
                        <label>唛头备注 <br /> (Marks Remark)</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" disabled="disabled" v-model="selectedOrder.marksRemark" />
                    </div>
                </div>
            </div>
            <div class="second-form">
                <div class="row" style="margin-top: 15px; margin-bottom: 15px; align-items: center">
                    <div class="col-md-1">
                        <label>取货方式 (Collect Type)</label>
                    </div>
                    <div class="col-md-2">
                        <input class="input-container" disabled="disabled" v-if="selectedOrder.collectType === ''" value="" />
                        <input class="input-container" disabled="disabled" v-if="selectedOrder.collectType === 'Self-Collection'" value="自提" />
                        <input class="input-container" disabled="disabled" v-if="selectedOrder.collectType === 'Delivery'" value="运送" />
                    </div>
                    <div class="col-md-1">
                        <label>送货地址 <br /> (Deliver Addr)</label>
                    </div>
                    <div class="col-md-8">
                        <input class="input-container" disabled="disabled" style="width: 100%" v-model="selectedOrder.deliveryAddress" />
                    </div>
                </div>
            </div>
            <div class="second-form">
                <div class="row" style="margin-top: 15px; margin-bottom: 15px; align-items: center">
                    <div class="col-md-1">
                        <label>品名 (Product Name)</label>
                    </div>
                    <div class="col-md-8">
                        <input v-if="selectedOrder.inventoryItems.length > 0" disabled="disabled" class="input-container" v-model="selectedOrder.inventoryItems[0].productName" />
                        <input v-else class="input-container" v-model="selectedOrder.inventoryItems[0].productName" />
                    </div>
                    <div class="col-md-1">
                        <label>货值 (Value)</label>
                    </div>
                    <div class="col-md-2">
                        <input v-if="selectedOrder.inventoryItems.length > 0" disabled="disabled" class="input-container" type="number" v-model="selectedOrder.inventoryItems[0].productValue" />
                        <input v-else class="input-container" v-model="selectedOrder.inventoryItems[0].productValue" />
                    </div>
                </div>
            </div>
            <div class="third-form">
                <div class="row" style="margin-top: 15px; margin-bottom: 15px; align-items: center">
                    <div class="col-md-1">
                        <label>数量 (Pkg)</label>
                    </div>
                    <div class="col-md-1">
                        <input class="input-container" v-model="totalQty" disabled="disabled" />
                    </div>
                    <div class="col-md-1">
                        <label>重量 (Wt)</label>
                    </div>
                    <div class="col-md-1">
                        <input v-if="selectedOrder.inventoryItems.length > 0" disabled="disabled" class="input-container" type="number" v-model="selectedOrder.inventoryItems[0].weight" />
                        <input v-else class="input-container" type="number" v-model="selectedOrder.inventoryItems[0].weight" />
                    </div>
                    <div class="col-md-1">
                        <label>体积 (m³)</label>
                    </div>
                    <div class="col-md-1">
                        <input class="input-container" v-model="totalm3" disabled="disabled" />
                    </div>
                    <div class="col-md-1">
                        <label>敏感货物 (Sens Pkg)</label>
                    </div>
                    <div class="col-md-1">
                        <input class="input-container" disabled="disabled" v-if="selectedOrder.inventoryItems[0].isSensitiveGood === true" style="color: red" value="是">
                        <input class="input-container" disabled="disabled" v-if="selectedOrder.inventoryItems[0].isSensitiveGood === false" value="否">
                    </div>
                    <div class="col-md-1">
                        <label>品名 (Location)</label>
                    </div>
                    <div class="col-md-3">
                        <input class="input-container" disabled="disabled" style="width: 100%" v-model="selectedOrder.inventoryLocations[0].location" />
                    </div>
                </div>
            </div>
            <div>
                <div class="row">
                    <div class="col-md-8" style="padding: 0">
                        <div style="overflow: auto; border: solid 1px #D8D7D7; margin: 0; padding: 0">
                            <table class="record-table">
                                <tr>
                                    <th>长 (cm)</th>
                                    <th>宽 (cm)</th>
                                    <th>高 (cm)</th>
                                    <th>数量</th>
                                    <th>体积 (m³)</th>
                                </tr>
                                <tr v-for="item in selectedOrder.inventoryItems" :key="item.inventoryItemId">
                                    <td>{{ item.length }}</td>
                                    <td>{{ item.width }}</td>
                                    <td>{{ item.height }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.m3 }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <the-footer></the-footer>
</template>

<script>
    export default {
        computed: {
            selectedOrder() {
                return this.$store.state.selectedOrder
            },
            totalQty() {
                var totalQty = this.selectedOrder.inventoryItems
                    .map(({ quantity }) => quantity)
                    .reduce((a, b) => a + b, 0)

                return totalQty
            },
            totalm3() {
                console.log('this')
                let m3 = this.selectedOrder.inventoryItems.map(({ m3 }) => m3).reduce((a, b) => a + b, 0)
                let weight = this.selectedOrder.inventoryItems.map(({ weight }) => weight)
                let lastweight = weight[0]
                console.log(m3)
                console.log(lastweight)

                if (m3 < 1) {
                    console.log('m3 less than 1')
                    if (lastweight > 250) {
                        console.log('1')
                        return 1
                    }
                    else {
                        return m3
                    }
                }

                else {
                    return m3
                }

            },
            formatDate() {
                let date = this.selectedOrder.receivingDate;

                let formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/`
                    + `${date.getDate().toString().padStart(2, '0')}/`
                    + `${date.getFullYear().toString().padStart(4, '0')} `
                    + `${(date.getHours() % 12 || 12).toString().padStart(2, '0')}:`
                    + `${date.getMinutes().toString().padStart(2, '0')}:`
                    + `${date.getSeconds().toString().padStart(2, '0')} `
                    + `${date.getHours() >= 12 ? 'PM' : 'AM'}`;

                return formattedDate;
            }
        },
    };
</script>

<style scoped>
    /* main */
    .first-form {
        border-top: solid 1px #D8D7D7;
        font-weight: bold;
    }

    .second-form {
        border-top: solid 1px #D8D7D7;
        font-weight: bold;
    }

    .third-form {
        border-top: solid 1px #D8D7D7;
        border-bottom: solid 1px #D8D7D7;
        font-weight: bold;
    }

    /* table */
    .record-table {
        width: 100%;
        table-layout: fixed;
        border: none;
        border-collapse: collapse;
    }

        .record-table tr th {
            width: 20%;
            padding: 10px;
            border-bottom: 1px solid #D8D7D7;
        }

        .record-table tr td {
            padding: 10px;
            border-bottom: 1px solid #D8D7D7;
        }

        .record-table td:nth-child(even) {
            background-color: #F5F5F5;
        }

        .record-table th:nth-child(even) {
            background-color: #F5F5F5;
        }

    .location-table {
        width: 100%;
        border: 1px solid #D8D7D7;
        border-top: none;
        border-right: none;
        font-weight: bold;
    }

        .location-table tr th {
            padding: 10px 5px;
            background-color: rgb(254, 244, 112, 0.2);
            border-bottom: 1px solid #D8D7D7;
        }

        .location-table tr td {
            padding: 10px 5px;
            border-bottom: 1px solid #D8D7D7;
        }


    /* icon */
    .magnifying-glass {
        font-size: 15px;
    }

    /* footer */
    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 65px;
        background-color: white;
    }

    /* bootstrap */
    .row {
        margin-left: auto;
        margin-right: auto;
    }

    /* set class style */
    .red {
        color: #FF0000;
        font-weight: bold;
    }

    .black {
        color: black;
        font-weight: bold;
    }


    @media screen and (max-width: 768px) {
        .md-btn {
            writing-mode: vertical-lr;
            display: inline-grid;
            align-items: center;
            clear: both;
        }

        button {
            margin-top: 20px;
            margin: 15px 0px 0px 5px;
        }
    }
</style>