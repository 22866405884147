<template>
    <footer>
        <table>
            <tr>
                <td style="width: 20%">
                    <span style="float: left;">
                        <img src="../../images/amcon_norm_logo.png" width="150" style="padding-left: 30px; padding-bottom: 30px;" />
                    </span>
                </td>
                <td style="width: 20%">
                    <span style="float: right;">
                        <img src="../../images/cargoerp_logo.png" width="150" style="padding-right: 30px; padding-bottom: 25px" />
                    </span>
                </td>
            </tr>
        </table>
    </footer>
</template>

<style scoped>
    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 8vh;
        background-color: white;
    }

    table {
        width: 100%;
    }

    .btn {
        padding: 7px 15px;
        background-color: #7e0204;
        border: none;
        color: white;
        cursor: pointer;
        vertical-align: middle;
        margin-bottom: 30px;
    }

        .btn:hover {
            background-color: rgb(126,2,4, 0.7);
            color: white;
        }
</style>