<template>
    <header>
        <div style="color: white; font-weight: bold; text-align: center; font-size: 20px;">
            <span>
                注册会员 (Register User)
            </span>
        </div>
    </header>

    <the-loader :isLoading="isLoading"></the-loader>

    <div class="main-page">
        <div class="main-container">
            <div class="form-container">
                <div class="row" style="margin: 1% 0%; align-items: center; font-weight: bold;">
                    <div class="col-12">
                        <div class="wrap-input100 validate-input">
                            <input class="input100" type="text" placeholder="姓名, Name" v-model="enteredCustName">
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <fa-fa icon="user"></fa-fa>
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="wrap-input100 validate-input">
                            <input class="input100" type="text" placeholder="备注, Remark" v-model="enteredCustRemark">
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <fa-fa icon="circle-info"></fa-fa>
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div style="margin-bottom: 10px; text-align: left;">
                            <div class="wrap-input100 validate-input" style="margin-bottom: 0;">
                                <input class="input100" type="text" placeholder="联络号码, Contact No" v-model="enteredCustContactNo">
                                <span class="focus-input100"></span>
                                <span class="symbol-input100">
                                    <fa-fa icon="phone" />
                                </span>
                            </div>
                            <label style="color: red; font-size: 13px;" v-if="contactMsg != ''">*{{ contactMsg }}</label>
                        </div>
                        
                    </div>
                    <div class="col-12">
                        <div class="wrap-input100 validate-input">
                            <input class="input100" type="text" placeholder="微信, WEIXIN" v-model="enteredCustWeiXin">
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <fa-fa icon="fa-brands fa-weixin" />
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="wrap-input100 validate-input">
                            <input class="input100" type="text" placeholder="QQ" v-model="enteredCustQq">
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <fa-fa icon="fa-brands fa-qq" />
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="wrap-input100 validate-input">
                            <input class="input100" type="text" placeholder="电邮, Email" v-model="enteredCustEmail">
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
                                <fa-fa icon="envelope" />
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row" style="background-color: #F5F5F5; padding-top: 15px; border-radius: 10px; margin-bottom: 10px; border: #D8D7D7 1px solid">
                            <div class="col-12">
                                <div class="wrap-input100 validate-input">
                                    <textarea class="input100" style="height: 70px; padding-top: 5px;" type="text" v-model="enteredAddress" placeholder="街道地址1, Street Address 1" />
                                    <span class="focus-input100"></span>
                                    <span class="symbol-input100">
                                        <fa-fa icon="map-location-dot" />
                                    </span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="wrap-input100 validate-input">
                                    <input class="input100" type="text" placeholder="邮编, Postal Code" v-model="enteredPostalCode">
                                    <span class="focus-input100"></span>
                                    <span class="symbol-input100">
                                        <fa-fa icon="map-pin" />
                                    </span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="wrap-input100 validate-input">
                                    <select class="input100" v-model="selectedType">
                                        <option value="" disabled hidden>地址类型, Address Type</option>
                                        <option value="Office">公司, Office</option>
                                        <option value="Home">住家, Home</option>
                                    </select>
                                    <span class="focus-input100"></span>
                                    <span class="symbol-input100">
                                        <fa-fa icon="tag" />
                                    </span>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <span>
                            <DxButton text="注册 (Register)"
                                      type="success"
                                      @click="register" />

                            <DxButton text="取消 (Cancel)"
                                      style="margin-left: 5px"
                                      type="danger"
                                      @click="loginPage" />
                        </span>
                    </div>
                </div>
            </div>

        </div>


    </div>
    <!--<the-footer></the-footer>-->
</template>

<script>
    import axios from 'axios'
    import { DxButton } from 'devextreme-vue/button';

    export default {
        components: {
            DxButton,
        },
        mounted() {
            const param1 = new URLSearchParams();
            const param2 = new URLSearchParams();
            const param3 = new URLSearchParams();
            param1.append('CurrencyType', 'Fixed Rate');
            param2.append('CustomerType', 'EGD');
            param3.append('Type', 'Exchange Rate');

            axios.post('https://app.amcon.express/booxApi/api/v2/masterData/getRateByType?' + param1 + '&' + param2 + '&' + param3)
                .then(response => {
                    const success = response.data["success"]
                    const msg = response.data["msg"]

                    if (success) {
                        this.rateList = response.data.result;
                        this.enteredRate = this.rateList.currencyRate
                    }
                    else {
                        alert(msg);
                    }
                });

            document.title = "注册会员"
        },
        data() {
            return {
                //customer details
                updatedDateTime: null,
                enteredCustName: '',
                enteredCustRemark: '',
                enteredCustContactNo: '',
                enteredCustWeiXin: '',
                enteredCustQq: '',
                enteredCustEmail: '',
                enteredMarks: '',
                enteredRate: 0,
                enteredAddress: '',
                enteredPostalCode: '',
                selectedType: '',
                isLoading: false,
                contactMsg: '',
            };
        },
        methods: {
            async register() {
                if (this.enteredCustContactNo === '') {
                    this.contactMsg = "联络号码不能为空 (Contact No cannot be empty)"
                }

                else {
                    this.isLoading = true;
                    axios.post('https://amcon.express/booxapi/api/v2/selfService/registerMarks', {
                        custName: this.enteredCustName,
                        custContactNo: this.enteredCustContactNo,
                        custWeiXin: this.enteredCustWeiXin,
                        custQq: this.enteredCustQq,
                        custEmail: this.enteredCustEmail,
                        custRemark: this.enteredCustRemark,
                        defaultCurRate: this.enteredRate,
                        defaultAddress: this.enteredAddress,
                        postalCode: this.enteredPostalCode,
                        addressType: this.selectedType,
                    })
                        .then(response => {
                            const success = response.data["success"]
                            const msg = response.data["msg"]
                            if (success === true) {
                                this.contactMsg = "";
                                this.isLoading = false;
                                this.$swal.fire({
                                    title: "注册成功\r\n(Registered Successfully)",
                                    html: msg,
                                    icon: "success",
                                    confirmButtonText: "确定 (Confirm)",
                                });
                                this.$router.push('/')
                            }
                            else {
                                this.isLoading = false;
                                alert(msg)
                            }

                        })
                        .catch(err => {
                            this.isLoading = false;
                            console.log(err)
                        })
                }
            },
            loginPage() {
                this.$router.push('/')
            }
        }
    };
</script>

<style scoped>
    /* main */
    .form-container {
        margin-top: 20px;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    input {
        outline: none;
        border: none;
    }

        input::-webkit-input-placeholder {
            color: #999999;
        }

        input:-moz-placeholder {
            color: #999999;
        }

        input::-moz-placeholder {
            color: #999999;
        }

        input:-ms-input-placeholder {
            color: #999999;
        }

    .input100 select::first-line {
        color: #999;
    }

    .validate-input {
        position: relative;
    }

    .wrap-input100 {
        position: relative;
        width: 100%;
        z-index: 1;
        margin-bottom: 10px;
    }

    .input100 {
        font-family: Poppins-Medium;
        font-size: 15px;
        line-height: 1.5;
        display: block;
        width: 100%;
        border: #e6e6e6 1px solid;
        height: 50px;
        border-radius: 5px;
        padding: 0 30px 0 68px;
    }

    .symbol-input100 {
        font-size: 15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        position: absolute;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-left: 35px;
        pointer-events: none;
        color: #666666;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
    }

    .input100:focus + .focus-input100 {
        -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
        animation: anim-shadow 0.5s ease-in-out forwards;
    }

        .input100:focus + .focus-input100 + .symbol-input100 {
            color: #AC3121; /* #57b846 */
            padding-left: 28px;
        }

    header {
        background: -webkit-linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        background: -o-linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        background: -moz-linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        background: linear-gradient(-135deg, #F17A30, #D15829, #AC3121);
        padding: 15px;
        align-items: center;
    }

    /* bootstrap */
    .row {
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 900px) {
        .row {
            align-items: center;
        }

        .form-container {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .input-container, .select-container {
            margin-bottom: 20px;
            width: 80%;
            align-self: center;
            height: 35px;
        }

        label {
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 768px) {
        .row {
            align-items: center;
        }

        .form-container {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        .input-container, .select-container {
            margin-bottom: 20px;
            width: 100%;
            align-self: center;
            height: 35px;
        }

        label {
            margin-bottom: 20px;
        }
    }
</style>