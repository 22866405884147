<template>
    <div class="image-container">
        <img src="../../images/amcon_norm_logo.png" class="logo-img" />
    </div>

    <div class="dashboard-container">
        <div class="row" style="text-align: center; border: solid 1px black; width: 80%;">
            <div class="row" style="margin-top: 10px; align-items: center;">
                <div class="col-6" style="text-align: left">
                    <img src="../../images/user.png" width="35" />
                    <label style="padding-left: 5px; padding-top: 5px; font-size: 15px; font-weight: bold; vertical-align: middle;">{{ custName }} <br /> <span style="font-size: 10px; vertical-align: top">{{ marks }}</span></label>
                </div>
                <div class="col-6" style="cursor: pointer; text-align: right">
                    <label style="font-size: 13px; padding-right: 5px; color: red; cursor: pointer; font-weight: bold;" @click="logout">登出</label>
                    <fa-fa icon="arrow-right-from-bracket" size="2xl" style="color: red" @click="logout" />
                </div>
            </div>

            <div class="col-md-12" style="margin-top: 50px; margin-bottom: 50px;">
                <div class="row" style="align-items: center;">
                    <div class="col-4">
                        <div>
                            <router-link to="/createOrder">
                                <img class="img-icon" src="../../images/order.png" @click="createOrder" />
                            </router-link>
                        </div>
                        <div>
                            <router-link to="/createOrder">
                                <label style="cursor: pointer" @click="createOrder">创建新预约</label>
                                <br />
                                <label style="cursor: pointer" @click="createOrder">Create Booking</label>
                            </router-link>
                        </div>

                    </div>
                    <div class="col-4">
                        <div>
                            <router-link to="/parcelLookup">
                                <img class="img-icon" src="../../images/lookup.png" />
                            </router-link>
                        </div>
                        <div>
                            <router-link to="/parcelLookup">
                                <label style="cursor: pointer">包裹查询</label>
                                <br />
                                <label style="cursor: pointer">(Parcel Lookup)</label>
                            </router-link>
                        </div>

                    </div>
                    <!--<div class="col-md-3 col-6">
                        <div>
                            <router-link to="/orderHistory">
                                <img class="img-icon" src="../../images/history.png" />
                            </router-link>
                        </div>
                        <div>
                            <router-link to="/orderHistory">
                                <label style="cursor: pointer">运单历史</label>
                                <br />
                                <label style="cursor: pointer">(Order History)</label>
                            </router-link>
                        </div>

                    </div>-->
                    <div class="col-4" v-if="custType !== 'VIP'">
                        <div>
                            <router-link to="/groupProfile">
                                <img class="img-icon" src="../../images/profile.png" />
                            </router-link>
                        </div>
                        <div>
                            <router-link to="/groupProfile">
                                <label style="cursor: pointer">用户资料</label>
                                <br />
                                <label style="cursor: pointer">User Profile</label>
                            </router-link>
                        </div>
                    </div>

                    <div class="col-4" v-if="custType === 'VIP'">
                        <div>
                            <router-link to="/BcProfile">
                                <img class="img-icon" src="../../images/profile.png" />
                            </router-link>
                        </div>
                        <div>
                            <router-link to="/BcProfile">
                                <label style="cursor: pointer">用户资料</label>
                                <br />
                                <label style="cursor: pointer">User Profile</label>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div style="margin-top: 20px;"></div>
            </div>

        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        mounted() {
            this.marks = this.$store.state.marks
            const params = new URLSearchParams();
            params.append('CustId', this.$store.state.custId);

            axios.post('https://amcon.express/booxapi/api/v2/selfService/getCustomerByCustId?' + params.toString())
                .then(response => {
                    console.log(response.data.result)
                    this.selectedCust = response.data.result;

                    this.custName = this.selectedCust.custName
                    this.custType = this.selectedCust.groupCode

                    console.log(this.selectedCust.addressBook)
                });

            document.title = "仪表板"
        },
        data() {
            return {
                custName: '',
                custType: '',
                marks: '',
            }
        },
        methods: {
            logout() {
                this.$store.state.custId = 0
                this.$router.push('/')
            }
        }
    };
</script>

<style scoped>
    .image-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 50px;
    }

    .dashboard-container {
        margin-top: 30px;
        text-align: center;
        position: absolute;
        width: 98%;
        font-size: 12px;
    }

    .img-icon {
        margin-top: 7px;
        margin-bottom: 5px;
        vertical-align: bottom;
        align-content: baseline;
        width: 72px;
    }

    .logo-img {
        width: 25%;
    }

    a {
        color: unset;
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;
    }

    .row {
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 768px) {
        .logo-img {
            width: 50%;
        }
    }

    @media screen and (max-width: 576px) {
        .logo-img {
            width: 50%;
        }
    }
</style>