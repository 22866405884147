<template>
    <the-header title="运单历史 (Order History)"></the-header>

    <the-loader :isLoading="isLoading"></the-loader>

    <history-detail v-if="historyDialog">
        <template #header>
            <div class="row">
                <div class="col-md-11">
                    <h4 style="font-weight: bold; padding-left: 80px;">{{ selectedHistory.courierName }} / {{ selectedHistory.parcelReference }}</h4>
                </div>
                <div class="col-md-1">
                    <fa-fa icon="times" class="delete-icon" style="float: right; margin-right: 20px;" @click="closeHistory()"></fa-fa>
                </div>
            </div>
        </template>
        <template #content>
            <div class="row" style="padding-top: 15px; padding-left: 15px; padding-bottom: 15px; border-bottom: 1px solid rgba(0, 0, 0, 0.5); align-items: center;">
                <div class="col-md-1">
                    取货方式 <br /> (Collect Type)
                </div>
                <div class="col-md-2">
                    <input class="input-container" v-if="selectedHistory.collectType === 'Self-Collection'" value="自提 (Self-Collection)" disabled="disabled" />
                    <input class="input-container" v-if="selectedHistory.collectType === 'Delivery'" value="运送 (Delivery)" disabled="disabled" />
                </div>
                <div class="col-md-1">
                    送货地址 <br /> (Deliver Addr)
                </div>
                <div class="col-md-8">
                    <input class="input-container" disabled="disabled" v-model="selectedHistory.deliveryAddress" style="width: 99%" />
                </div>
            </div>
            <div class="row" style="padding-top: 15px; padding-left: 15px; padding-bottom: 15px; border-bottom: 1px solid rgba(0, 0, 0, 0.5); align-items: center;">
                <div class="col-md-1">
                    品名 <br /> (Product Name)
                </div>
                <div class="col-md-8">
                    <input class="input-container" disabled="disabled" v-model="selectedHistory.inventoryItems[0].productName" style="width: 99%" />
                </div>
                <div class="col-md-1">
                    货值 (Value)
                </div>
                <div class="col-md-2">
                    <input class="input-container" disabled="disabled" v-model="selectedHistory.inventoryItems[0].productValue" />
                </div>
            </div>
            <div class="row" style="padding-top: 15px; padding-left: 15px; padding-bottom: 15px; border-bottom: 1px solid rgba(0, 0, 0, 0.5); align-items: center; ">
                <div class="col-md-1">
                    签收日期 <br /> (Rec Date)
                </div>
                <div class="col-md-2">
                    <input class="input-container" type="date" disabled="disabled" v-model="currentDate" />
                </div>
                <div class="col-md-1">
                    发往目的地 <br /> (In Transit)
                </div>
                <div class="col-md-2">
                    <input class="input-container" disabled="disabled" />
                </div>
                <div class="col-md-1">
                    抵达目的地 <br /> (Arrived)
                </div>
                <div class="col-md-2">
                    <input class="input-container" disabled="disabled" />
                </div>
                <div class="col-md-1">
                    提货日期 <br /> (Pickup Date)
                </div>
                <div class="col-md-2">
                    <input class="input-container" disabled="disabled" />
                </div>

            </div>
            <div class="row" style="padding: 15px; border-bottom: 1px solid rgba(0, 0, 0, 0.5); align-items: center;">
                <div class="col-md-1">
                    数量 (Pkg)
                </div>
                <div class="col-md-1">
                    <input class="input-container" style="width: 100%;" disabled="disabled" v-model="selectedHistory.inventoryItems[0].totalPackage" />
                </div>
                <div class="col-md-1">
                    重量 (Wt)
                </div>
                <div class="col-md-1">
                    <input class="input-container" style="width: 100%;" disabled="disabled" v-model="selectedHistory.inventoryItems[0].weight" />
                </div>
                <div class="col-md-1">
                    体积 (m3)
                </div>
                <div class="col-md-1">
                    <input class="input-container" style="width: 100%;" disabled="disabled" v-model="selectedHistory.inventoryItems[0].actualM3" />
                </div>
                <div class="col-md-1">
                    敏感货物 <br /> (Sens Pkg)
                </div>
                <div class="col-md-1">
                    <input class="input-container" style="width: 100%; color: red" v-if="selectedHistory.inventoryItems[0].isSensitiveGood === true" value="是" disabled="disabled" />
                    <input class="input-container" style="width: 100%;" v-if="selectedHistory.inventoryItems[0].isSensitiveGood === false" value="否" disabled="disabled" />
                </div>
            </div>
            <table class="record-table">
                <tr>
                    <th>长 (cm)</th>
                    <th>宽 (cm)</th>
                    <th>高 (cm)</th>
                    <th>数量</th>
                    <th>体积 (m³)</th>
                </tr>
                <tr v-for="item in selectedHistory.inventoryItems" :key="item.inventoryItemId">
                    <td>{{ item.length }}</td>
                    <td>{{ item.width }}</td>
                    <td>{{ item.height }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.m3 }}</td>
                </tr>
            </table>
        </template>
    </history-detail>

    <div class="main-page">
        <div class="main-container">
            <div style="padding: 15px 0px;">
                <span style="color: red">
                    最多显示 30 天记录 (Maximum 30 Days of record displayed)
                </span>
            </div>

            <DxDataGrid id="gridContainer"
                        :ref="dataGridRefName"
                        :data-source="orderList"
                        key-expr="id"
                        :word-wrap-enabled="true"
                        :remote-operations="true"
                        :show-borders="true"
                        style="height: 92%;">
                <DxSearchPanel :visible="true"
                               :width="240"
                               placeholder="Search..." />
                <DxFilterRow :visible="true"
                             :apply-filter="currentFilter" />

                <DxColumn data-field="bookingDate"
                          caption="创建日期 (Create Date)"
                          data-type="date" />

                <DxColumn data-field="marks"
                          caption="唛头 (Marks)" />

                <DxColumn data-field="courierName"
                          caption="快递公司 (Courier)" />

                <DxColumn data-field="parcelReference"
                          width="150"
                          caption="运单号 (Parcel Ref)" />

                <DxColumn data-field="totalQuantity"
                          caption="数量 (Pkg)" />

                <DxColumn data-field="totalWeight"
                          caption="重量 (Wt)" />

                <DxColumn data-field="totalM3"
                          caption="体积 (m³)" />

                <DxColumn data-field="isSensitive"
                          caption="敏感货物 (Sens Pkg)">
                    <DxLookup :data-source="sensitive"
                              value-expr="id"
                              display-expr="text" />
                </DxColumn>

                <DxColumn data-field="clearanceDate"
                          caption="清货日期 (Clearance Date)"
                          data-type="date" />

                <DxColumn data-field="paymentDate"
                          caption="付款日期 (Payment Date)"
                          data-type="date" />

                <DxColumn data-field="paymentAmount"
                          caption="支付金额 (Payment Amount)"
                          data-type="date" />

                <DxColumn type="buttons">
                    <DxButton icon="info"
                              text="View"
                              @click="viewOrder" />
                </DxColumn>

                <DxPaging :page-size="10" />
                <DxPager :visible="true"
                         :allowed-page-sizes="[10, 20, 50]"
                         :show-page-size-selector="true"
                         :show-info="true"
                         :show-navigation-buttons="true" />

            </DxDataGrid>
            
        </div>
    </div>

    <the-footer></the-footer>
</template>

<script>
    import axios from 'axios';
    import {
        DxDataGrid,
        DxColumn,
        DxFilterRow,
        DxSearchPanel,
        DxLookup,
        DxButton,
        DxPager,
        DxPaging,
    } from 'devextreme-vue/data-grid';

    const sensitive = [{
        id: true, text: '是',
    }, {
        id: false, text: '',
    }];


    export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxFilterRow,
            DxSearchPanel,
            DxLookup,
            DxButton,
            DxPager,
            DxPaging,
        },
        data() {
            return {
                sensitive,
                orderList: [],
            }
        },
        mounted() {
            const params = new URLSearchParams();
            params.append('CustId', this.$store.state.custId);

            axios.get('https://amcon.express/booxapi/api/v2/selfService/getOrdersByCustId?' + params.toString())
                .then(response => {
                    this.orderList = response.data.data;
                });
        },
        methods: {
            viewOrder(id) {
                this.$store.commit('setSelectedHistory', id)
                this.historyDialog = true;
            },
            closeHistory() {
                this.historyDialog = false
            },
        },
    };
</script>

<style scoped>
    /* icon */
    .magnifying-glass {
        font-size: 15px;
    }

    /* table */
    .record-table {
        width: 50%;
        table-layout: fixed;
        border: 1px solid #D8D7D7;
        border-collapse: collapse;
        border-top: hidden;
    }

        .record-table tr th {
            width: 20%;
            padding: 10px;
            border-bottom: 1px solid #D8D7D7;
        }

        .record-table tr td {
            padding: 10px;
            border-bottom: 1px solid #D8D7D7;
        }

        .record-table td:nth-child(even) {
            background-color: #F5F5F5;
        }

        .record-table th:nth-child(even) {
            background-color: #F5F5F5;
        }


    /* footer */
    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 65px;
        background-color: white;
    }

    /* bootstrap */
    .row {
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 768px) {
        button {
            margin-bottom: 5px;
            margin-top: 5px;
        }

        img {
            margin: 20px 0px;
        }
    }
</style>