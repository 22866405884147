<template>
    <the-header title="包裹查询 (Parcel Lookup)"></the-header>

    <the-loader :isLoading="isLoading"></the-loader>

    <edit-booking v-if="openEdit">
        <template #header>
            <div class="row">
                <div class="col-md-11">
                    <h4 style="font-weight: bold; padding-left: 80px; font-weight: bold;">
                        编辑 {{ enteredInternalRef }} 预约
                    </h4>
                </div>
                <div class="col-md-1">
                    <fa-fa icon="times" class="delete-icon" style="float: right; margin-right: 20px; margin-top: 3px;" @click="closeEdit()"></fa-fa>
                </div>
            </div>
        </template>
        <template #content>
            <div class="row">
                <div class="col-12" style="margin-top: 50px;">
                    <div class="row" style="align-items: center;">
                        <div class="col-2">
                            <label class="label-container">预约日期 <br /> (Booking Date)</label>
                        </div>
                        <div class="col-4">
                            <input type="date" v-model="enteredReceivingDate" disabled>
                        </div>

                        <div class="col-2">
                            <label class="label-container">唛头 <br /> (Marks)</label>
                        </div>
                        <div class="col-4">
                            <input type="text" v-model="enteredMarks" disabled>
                        </div>

                        <div class="col-2">
                            <label class="label-container">快递公司 <br /> (Courier Name)</label>
                        </div>
                        <div class="col-4">
                            <select class="select-container" v-model="enteredCourier">
                                <option v-for="courier in courierList" :value="courier.text" :key="courier.text">{{ courier.text }}</option>
                            </select>
                        </div>

                        <div class="col-2">
                            <label class="label-container">运单号 <br /> (Parcel Reference)</label>
                        </div>
                        <div class="col-4">
                            <input type="text" class="input-container" v-model="enteredParcelRef" />
                        </div>


                        <div class="col-2">
                            <label class="label-container">品名 <br /> (Product Name)</label>
                        </div>
                        <div class="col-4">
                            <input class="input-container" v-model="enteredProductName" />
                        </div>

                        <div class="col-2">
                            <label class="label-container">货值 <br /> (Product Value)</label>
                        </div>
                        <div class="col-4">
                            <input type="number" min="0" class="input-container" v-model="enteredProductValue" :disabled="disabled" />
                            <!--<input type="number" min="0" class="input-container" v-model="enteredProductValue" v-else />-->
                        </div>
                        <div class="col-2">
                            <label class="label-container">取货方式 <br /> (Collect Type)</label>
                        </div>
                        <div class="col-4">
                            <select class="select-container" v-model="enteredCollection">
                                <option value="Self-Collection">自提 (Self-Collection)</option>
                                <option value="Delivery">送货 (Delivery)</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-12" style="text-align: center; margin-top: 50px; margin-bottom: 50px;">
                    <DxButton2 icon="save"
                               text="保存"
                               type="success"
                               @click="saveBooking" />
                </div>
            </div>
        </template>
    </edit-booking>

    <edit-booking v-if="openEditOrder">
        <template #header>
            <div class="row">
                <div class="col-md-11">
                    <h4 style="font-weight: bold; padding-left: 80px; font-weight: bold;">
                        编辑 {{ enteredInternalRef }} 订单
                    </h4>
                </div>
                <div class="col-md-1">
                    <fa-fa icon="times" class="delete-icon" style="float: right; margin-right: 20px; margin-top: 3px;" @click="closeEditOrder()"></fa-fa>
                </div>
            </div>
        </template>
        <template #content>
            <div class="row">
                <div class="col-12" style="margin-top: 50px;">
                    <div class="row" style="align-items: center;">
                        <div class="col-2">
                            <label class="label-container">收货日期 <br /> (Receiving Date)</label>
                        </div>
                        <div class="col-4">
                            <input type="date" v-model="enteredReceivingDate" disabled>
                        </div>

                        <div class="col-2">
                            <label class="label-container">唛头 <br /> (Marks)</label>
                        </div>
                        <div class="col-4">
                            <input type="text" v-model="enteredMarks" disabled>
                        </div>

                        <div class="col-2">
                            <label class="label-container">快递公司 <br /> (Courier Name)</label>
                        </div>
                        <div class="col-4">
                            <select class="select-container" v-model="enteredCourier">
                                <option v-for="courier in courierList" :value="courier.text" :key="courier.text">{{ courier.text }}</option>
                            </select>
                        </div>

                        <div class="col-2">
                            <label class="label-container">运单号 <br /> (Parcel Reference)</label>
                        </div>
                        <div class="col-4">
                            <input type="text" class="input-container" v-model="enteredParcelRef" />
                        </div>


                        <div class="col-2">
                            <label class="label-container">品名 <br /> (Product Name)</label>
                        </div>
                        <div class="col-4">
                            <input class="input-container" v-model="enteredProductName" />
                        </div>

                        <div class="col-2">
                            <label class="label-container">货值 <br /> (Product Value)</label>
                        </div>
                        <div class="col-4">
                            <input type="number" min="0" class="input-container" v-model="enteredProductValue" :disabled="disabled" />
                            <!--<input type="number" min="0" class="input-container" v-model="enteredProductValue" v-else />-->
                        </div>
                        <div class="col-2">
                            <label class="label-container">取货方式 <br /> (Collect Type)</label>
                        </div>
                        <div class="col-4">
                            <select class="select-container" v-model="enteredCollection">
                                <option value="Self-Collection">自提 (Self-Collection)</option>
                                <option value="Delivery">送货 (Delivery)</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-12" style="text-align: center; margin-top: 50px; margin-bottom: 50px;">
                    <DxButton2 icon="save"
                               text="保存"
                               type="success"
                               @click="saveOrder" />
                </div>
            </div>
        </template>
    </edit-booking>

    <div class="main-page">
        <div class="main-container">
            <div class="row" style="margin: 0;">
                <div class="col-4" style="padding: 10px; font-size: 15px; font-weight: bold; text-align: left; margin: 0; align-content: center;">
                    唛头 (Marks) : {{ this.enteredMarks }}
                </div>
                <div class="col-4" style="padding: 10px; font-size: 15px; font-weight: bold; text-align: left; margin: 0">
                    <select class="select-container" v-model="selectedStatus" @change="filterStatus" style="margin: 0; height: 30px; border-radius: 2px;">
                        <option value="all">全部</option>
                        <option value="UNPAID">未支付</option>
                        <option value="PAID">已支付</option>
                    </select>
                </div>
                <div class="col-4" style="text-align: right;">
                    <DxButton2 icon="refresh"
                               text="刷新"
                               style="float: right; margin: 5px;"
                               @click="refreshGrid" />
                </div>
            </div>

            <div class="row" style="text-align: right; padding-bottom: 10px; border-bottom: 2px solid #d2d6de">
                <span class="style-label">合计{{ totalAmt.toFixed(3) }}元</span>
            </div>

            <div class="row">
                <DxDataGrid id="gridContainer"
                            ref="datagrid"
                            :data-source="parcelList"
                            :hover-state-enabled="true"
                            :word-wrap-enabled="true"
                            :column-auto-width="true"
                            :row-alternation-enabled="true"
                            data-row-template="dataRowTemplate"
                            :show-borders="false"
                            :show-column-headers="false"
                            :show-row-lines="true"
                            :show-column-lines="false">

                    <DxColumn />
                    <DxColumn />

                    <template #dataRowTemplate="{ data: rowInfo }">
                        <tr class="main-row" role="row">
                            <td style="text-align: left; padding-bottom: 30px;">
                                #{{ rowInfo.data.id }} <br />
                                时间: {{ formattedDate(rowInfo.data.receivingDate) }} <br />
                                体积: {{ rowInfo.data.totalM3 }}m&sup3;&emsp;数量: {{ rowInfo.data.totalQuantity }}&emsp;货值: {{ rowInfo.data.productValue }} <br />
                                收据号: {{ rowInfo.data.manualReceiptNo }} <br />
                                物流:
                                <span class="style-label-parcel" v-if="rowInfo.data.totalQuantity === 0" @click="editBooking(rowInfo.data.id)">
                                    {{ rowInfo.data.courierName }} {{ rowInfo.data.parcelReference }}
                                </span>
                                <span class="style-label-parcel" v-if="rowInfo.data.totalQuantity > 0" @click="editOrder(rowInfo.data.id)">
                                    {{ rowInfo.data.courierName }} {{ rowInfo.data.parcelReference }}
                                </span>
                            </td>
                            <td style="text-align: right;">
                                <span class="style-label">S${{ (rowInfo.data.paymentAmount/rowInfo.data.exchangeRate).toFixed(3) }}</span><br />
                                <span v-if="rowInfo.data.paymentStatus === 'PAID'">已付款</span>
                                <span v-if="rowInfo.data.paymentStatus === 'UNPAID'">未付款</span>
                            </td>
                            <td>
                                <DxButton2 icon="trash"
                                           style="background-color: transparent; border: none; color: #337ab7"
                                           v-if="rowInfo.data.cargoStatus === 'Booking Created'"
                                           @click="deleteBooking(rowInfo.data.id, rowInfo.data.parcelReference)" />
                            </td>
                        </tr>
                    </template>

                    <DxPaging :page-size="16" />
                    <DxPager :visible="true"
                             :allowed-page-sizes="[16, 20, 50]"
                             :show-page-size-selector="true"
                             :show-info="true"
                             :show-navigation-buttons="true" />

                </DxDataGrid>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {
        DxDataGrid,
        DxColumn,
        DxPager,
        DxPaging,
    } from 'devextreme-vue/data-grid';
    import DxButton2 from 'devextreme-vue/button';
    import { toast } from 'vue3-toastify';

    export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxPager,
            DxPaging,
            DxButton2
        },
        data() {
            return {
                parcelDialog: false,

                // loader
                isLoading: false,

                parcelList: [],
                enteredReceivingDate: '',
                enteredInternalRef: '',
                enteredCourier: '',
                enteredParcelRef: '',
                enteredMarks: '',
                enteredProductName: '',
                enteredProductValue: '',
                enteredCollection: '',

                courierList: [],
                openEdit: false,
                openEditOrder: false,
                openView: false,
                status,
                bookingId: 0,
                orderId: 0,
                disabled: false,
                exchangeRate: 0,
                selectedStatus: 'all',
            }
        },
        async mounted() {
            this.enteredMarks = this.$store.state.marks
            const params = new URLSearchParams();
            params.append('Marks', this.enteredMarks);

            axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcel?' + params.toString())
                .then(response => {
                    this.parcelList = response.data.data;
                });

            await axios.get('https://amcon.express/booxapi/api/v2/selfService/courierLookup')
                .then(response => {
                    this.courierList = response.data.data
                });

            await axios.get('https://app.amcon.express/booxapi/api/V2/masterData/getEGDExchangeRate',
            ).then(response => {
                this.isLoading = false;
                this.exchangeRate = response.data.result;
            });

            document.title = "包裹追踪"
        },
        computed: {
            totalAmt() {
                const amt = this.parcelList.map((item) => item.paymentAmount);
                const totalAmt = amt.reduce((a, b) => a + b, 0);
                return totalAmt / this.exchangeRate;
            },
        },
        methods: {
            editBooking(id) {
                this.disabled = false;
                this.isLoading = true;
                this.openEdit = true;
                //const data = e.row && e.row.data;
                //const id = data && data.id;
                this.bookingId = id;
                this.$store.commit('setSelectedBooking', id)

                const params = new URLSearchParams();
                params.append('BookingID', this.$store.state.selectedBooking);

                axios.post('https://amcon.express/booxapi/api/v2/selfService/getBookingDetail?' + params.toString())
                    .then(response => {
                        this.isLoading = false;
                        this.selectedBookingList = response.data.result;

                        this.enteredReceivingDate = this.selectedBookingList.bookingDate.substr(0, 10)
                        this.enteredInternalRef = this.selectedBookingList.internalReference
                        this.enteredCourier = this.selectedBookingList.courierName
                        this.enteredParcelRef = this.selectedBookingList.parcelReference
                        this.enteredProductValue = this.selectedBookingList.productValue
                        this.enteredMarks = this.selectedBookingList.marks
                        this.enteredProductName = this.selectedBookingList.productName
                        this.enteredCollection = this.selectedBookingList.collectType

                        if (this.selectedBookingList.productValue > 0) {
                            this.disabled = true;
                        }
                    });
            },
            closeEdit() {
                this.openEdit = false
            },
            editOrder(id) {
                this.disabled = false;
                this.isLoading = true;
                this.openEditOrder = true;
                //const data = e.row && e.row.data;
                //const id = data && data.id;
                this.orderId = id;
                const params = new URLSearchParams();
                params.append('OrderID', id);

                axios.post('https://amcon.express/booxapi/api/v2/selfService/getOrderDetail?' + params.toString())
                    .then(response => {
                        this.isLoading = false;
                        this.selectedOrder = response.data.result;

                        this.enteredReceivingDate = this.selectedOrder.receivingDate.substr(0, 10)
                        this.enteredInternalRef = this.selectedOrder.internalReference
                        this.enteredCourier = this.selectedOrder.courierName
                        this.enteredParcelRef = this.selectedOrder.parcelReference
                        this.enteredProductValue = this.selectedOrder.productValue
                        this.enteredMarks = this.selectedOrder.marks
                        this.enteredProductName = this.selectedOrder.productName
                        this.enteredCollection = this.selectedOrder.collectType

                        if (this.selectedOrder.productValue > 0) {
                            this.disabled = true;
                        }
                    });
            },
            closeEditOrder() {
                this.openEditOrder = false
            },
            saveBooking() {
                this.isLoading = true;
                axios.post('https://amcon.express/booxapi/api/v2/selfService/saveBooking', {
                    bookingId: this.bookingId,
                    courierName: this.enteredCourier,
                    parcelReference: this.enteredParcelRef,
                    productName: this.enteredProductName,
                    productValue: this.enteredProductValue,
                    collectType: this.enteredCollection,
                })
                    .then(response => {
                        const success = response.data["success"]
                        const msg = response.data["msg"]

                        if (success === true) {
                            this.$swal.fire({
                                title: "保存成功",
                                text: "Booking Saved Successfully",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1500
                            });
                            this.isLoading = false;

                            const params = new URLSearchParams();
                            params.append('Marks', this.enteredMarks);

                            axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcel?' + params.toString())
                                .then(response => {
                                    this.parcelList = response.data.data;
                                });

                            this.openEdit = false;
                        }
                        else {
                            this.isLoading = false;
                            toast.error(msg, {
                                position: 'top-center',
                                timeout: 2000,
                            });
                            this.openEdit = false;
                        }
                    })
                    .catch(err => {
                        this.isLoading = false;
                        console.log(err)
                    })
            },
            deleteBooking(id, ref) {
                //const data = e.row && e.row.data;
                //const id = data && data.id;
                //const ref = data && data.parcelReference;

                this.$swal.fire({
                    title: "确定删除运单" + ref + "?",
                    text: "Confirm Delete Parcel " + ref + "?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "确定 (Confirm)",
                    cancelButtonText: "取消 (Cancel)"
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('https://amcon.express/booxapi/api/v2/selfService/deleteBooking', {
                            bookingId: id,
                        })
                            .then(response => {
                                const success = response.data["success"]
                                const msg = response.data["msg"]
                                if (success === true) {
                                    this.$swal.fire({
                                        title: "已删除运单" + ref,
                                        text: ref + " Booking Deleted",
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 1500
                                    });

                                    const params = new URLSearchParams();
                                    params.append('Marks', this.enteredMarks);

                                    axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcel?' + params.toString())
                                        .then(response => {
                                            this.parcelList = response.data.data;
                                        });
                                }
                                else {
                                    this.$swal.fire({
                                        title: msg,
                                        text: "删除" + ref + "失败",
                                        icon: "error"
                                    });
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })

                    }
                });


            },
            saveOrder() {
                this.isLoading = true;
                axios.post('https://amcon.express/booxapi/api/v2/selfService/saveOrder', {
                    customerOrderId: this.orderId,
                    courierName: this.enteredCourier,
                    parcelReference: this.enteredParcelRef,
                    productName: this.enteredProductName,
                    productValue: this.enteredProductValue,
                    collectType: this.enteredCollection,
                })
                    .then(response => {
                        const success = response.data["success"]
                        const msg = response.data["msg"]

                        if (success === true) {
                            this.$swal.fire({
                                title: "保存成功",
                                text: "Order Saved Successfully",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1500
                            });
                            this.isLoading = false;

                            const params = new URLSearchParams();
                            params.append('Marks', this.enteredMarks);

                            axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcel?' + params.toString())
                                .then(response => {
                                    this.parcelList = response.data.data;
                                });

                            this.openEditOrder = false;
                        }
                        else {
                            this.isLoading = false;
                            toast.error(msg, {
                                position: 'top-center',
                                timeout: 2000,
                            });
                            this.openEditOrder = false;
                        }
                    })
                    .catch(err => {
                        this.isLoading = false;
                        console.log(err)
                    })
            },
            refreshGrid() {
                const params = new URLSearchParams();
                params.append('Marks', this.enteredMarks);

                axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcel?' + params.toString())
                    .then(response => {
                        this.parcelList = response.data.data;
                    });
            },
            filterStatus() {
                const params = new URLSearchParams();
                const status = new URLSearchParams();
                params.append('Marks', this.enteredMarks);
                status.append('PaymentStatus', this.selectedStatus);
                if (this.selectedStatus !== 'all') {
                    axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcelbyStatus?' + params + '&' + status)
                        .then(response => {
                            this.parcelList = response.data.data;
                        });
                }
                else {
                    axios.get('https://amcon.express/booxapi/api/v2/selfService/getParcel?' + params.toString())
                        .then(response => {
                            this.parcelList = response.data.data;
                        });
                }
            },
            formattedDate(dateString) {
                const date = new Date(dateString);
                return date.toLocaleDateString('en-SG', { month: 'short', day: 'numeric', year: 'numeric' });
            }
        }
    };
</script>

<style scoped>
    input[type=text]:disabled {
        height: 35px;
        text-align: left;
        padding-left: 10px;
        width: 100%;
        margin-top: 30px;
        outline: none;
        font-size: 13px;
    }

    input[type=date]:disabled {
        height: 35px;
        text-align: left;
        padding-left: 10px;
        width: 100%;
        margin-top: 30px;
        outline: none;
        font-size: 13px;
    }

    .input-container, .select-container {
        height: 35px;
        text-align: left;
        padding-left: 10px;
        width: 100%;
        margin-top: 30px;
        outline: none;
    }

        .input-container:focus, .input-container:hover {
            border: 1px solid #B1CCE3;
        }

    .WrappedColumnClass {
        white-space: normal;
        word-wrap: break-word;
    }

    .dx-autocomplete {
        width: 100%;
        height: 35px;
        text-align: left;
        font-size: 16px;
        font-weight: bolder;
        outline-color: black;
        padding-left: 10px;
        margin-top: 30px;
        margin-bottom: 5px;
        border-radius: 0px;
        font-size: 13px;
    }

    .label-container {
        margin-top: 30px;
        text-align: center;
        width: 100%;
    }
    .row {
        margin-left: auto;
        margin-right: auto;
    }
    #gridContainer {
        height: 100%;
    }
    .main-container {
        max-width: 600px;
        position: unset;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
    }
    .style-label {
        color: #337ab7
    }
    .style-label-parcel {
        color: #337ab7;
        cursor: pointer;
    }
        .style-label-parcel:hover {
            color: rgb(51, 122, 183, 0.7);
        }
    .main-row {
        border-top: 1px solid #d2d6de;
    }
    /*.dx-button-mode-contained {
        background-color: transparent;
        border: none;
        color: #337ab7
    }*/
</style>
